import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import teamMembers from "../../data/team";

const TeamTwo = () => {
  useEffect(() => {
    const $ = window.$;

    if ($(".project-three__carousel").length) {
      $(".project-three__carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        smartSpeed: 500,
        autoHeight: false,
        autoplay: true,
        dots: false,
        autoplayTimeout: 3000,
        navText: [
          '<i className="fa fa-angle-double-left" aria-hidden="true"></i>',
          '<i className="fa fa-angle-double-right" aria-hidden="true"></i>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          750: {
            items: 2,
          },
          1100: {
            items: 3,
          },
          1350: {
            items: 4,
          },
        },
      });
    }

    if ($(".img-popup").length) {
      var groups = {};
      $(".img-popup").each(function () {
        var id = parseInt($(this).attr("data-group"), 10);

        if (!groups[id]) {
          groups[id] = [];
        }

        groups[id].push(this);
      });

      $.each(groups, function () {
        $(this).magnificPopup({
          type: "image",
          closeOnContentClick: true,
          closeBtnInside: false,
          gallery: {
            enabled: true,
          },
        });
      });
    }
  }, []);

  const publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <>
      <section className="project-three">
        <div className="container-fluid">
          <div className="sec-title text-center">
            <div className="sec-title__tagline">
              <span className="left"></span>
              <h6>Team</h6> <span className="right"></span>
            </div>
            <h2 className="sec-title__title">
              Meet our talented, skilled <br /> and friendly team!
            </h2>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="project-three__carousel owl-carousel owl-theme">
                {teamMembers.map((member) => (
                  <div className="project-three__single" key={member.id}>
                    <div className="project-three__single-img">
                      <img
                        src={publicUrl + `assets/images/team/${member.image}`}
                        alt="#"
                      />
                      <div className="icon-box">
                        <a
                          className="img-popup"
                          href={
                            publicUrl + `assets/images/team/${member.image}`
                          }
                        >
                          <span className="icon-plus-sign"></span>
                        </a>
                      </div>
                      <div className="content-box">
                        <p>{member.role}</p>
                        <h3>
                          <Link>{member.name}</Link>
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamTwo;
