import React, { useEffect } from "react";
import cardMenu from "../../data/cardMenu";

const TestimonialOne = () => {
  useEffect(() => {
    const $ = window.$;

    if ($(".testimonials-one__carousel").length) {
      $(".testimonials-one__carousel").owlCarousel({
        loop: true,
        margin: 30,
        nav: false,
        smartSpeed: 500,
        autoHeight: false,
        autoplay: true,
        dots: false,
        autoplayTimeout: 7000,
        navText: [
          '<i className="fa fa-angle-double-left" aria-hidden="true"></i>',
          '<i className="fa fa-angle-double-right" aria-hidden="true"></i>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          800: {
            items: 2,
          },
          992: {
            items: 2,
          },
          1200: {
            items: 3,
          },
        },
      });
    }
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <>
      <section className="testimonials-one-sec">
        <div className="shape1">
          <img
            src={publicUrl + "assets/images/shapes/testimonials-v1-shape1.png"}
            alt=""
          />
        </div>
        <div className="shape2">
          <img
            src={publicUrl + "assets/images/shapes/testimonials-v1-shape2.png"}
            alt=""
          />
        </div>
        <div className="container">
          <div className="sec-title text-center">
            <div className="sec-title__tagline">
              <span className="left"></span>
              <h6>MORE OF WHAT WE OFFER</h6>
              <span className="right"></span>
            </div>
            <h2 className="sec-title__title"></h2>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="testimonials-one__carousel owl-carousel owl-theme">
                {/* Map through the cardMenu array to generate testimonials */}
                {cardMenu.map((item, index) => (
                  <div className="testimonials-one__single" key={index}>
                    <div className="fontAwesomeIcon">{item.icon}</div>

                    <div className="title-box">
                      <h2>{item.title}</h2>
                      <hr style={{ width: "9%" }}></hr>
                    </div>

                    <div className="text-box">
                      <p>{item.content}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialOne;
