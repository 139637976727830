import React, { useEffect } from "react";

const AboutOne = () => {
  useEffect(() => {
    const $ = window.$;

    if ($(".count-bar").length) {
      $(".count-bar").appear(
        function () {
          var el = $(this);
          var percent = el.data("percent");
          $(el).css("width", percent).addClass("counted");
        },
        {
          accY: -50,
        }
      );
    }

    if ($(".odometer").length) {
      var odo = $(".odometer");
      odo.each(function () {
        $(this).appear(function () {
          var countNumber = $(this).attr("data-count");
          $(this).html(countNumber);
        });
      });
    }
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";
  
  return (
    <section className="about-one-sec">
      <div className="container-fluid">
        <div className="row">
          {/* Start About One Img */}
          <div className="col-xl-6">
            <div className="about-one__img-box">
              <div
                className="about-one__img-box-bg"
                style={{
                  backgroundImage:
                    "url(" +
                    publicUrl +
                    "assets/images/about/about-v1-img2.jpg)",
                }}
              ></div>

              <div className="about-one__img-box-overlay">
                <h2>
                  <span className="odometer" data-count="100">
                    00
                  </span>
                  <span className="plus">+</span> <br />
                  Projects
                  <br />
                  Done
                </h2>
              </div>
            </div>
          </div>
          {/* End About One Img */}

          {/* Start About One Content */}
          <div className="col-xl-6">
            <div className="about-one__content">
              <div className="sec-title">
                <div className="sec-title__tagline">
                  <h6>About Company</h6> <span className="right"></span>
                </div>
                <h2 className="sec-title__title" style={{ lineHeight: "65px" }}>
                  Engineering Design Construction <br />
                  Culture
                </h2>
                <p className="sec-title__text" style={{ lineHeight: "40px" }}>
                  As a level one B-BBEEE enterprise, we recognize that it is
                  in our DNA to build the finest economy in the country.
                  Using this approach, we have built a culture that is
                  driven by the desire to be the best at providing highly
                  customized, superior quality service, allowing us to go
                  above and beyond to meet and exceed our customers' goals
                  and objectives.
                </p>
              </div>

              <div className="about-one__conten-bottom">
                <div className="about-one__progress">
                  {/* Progress bars can be added here */}
                </div>
              </div>
            </div>
          </div>
          {/* End About One Content */}
        </div>
      </div>
    </section>
  );
};

export default AboutOne;
