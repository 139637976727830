import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

const BrandOne = () => {
  let publicUrl = process.env.PUBLIC_URL + '/';

  // Create an array with image paths
  const brandData = [
    'brand-v1-img5.jpg',
    'brand-v1-img6.jpg',
    'brand-v1-img7.jpg',
  ];

  return (
    <>
      <section className="brand-one-sec">
        <div
          className="brand-one__bg"
          style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/brand-v1-bg.png)' }}
        ></div>
        <div className="container">
          <Swiper
            loop={true}
            spaceBetween={50}
            slidesPerView={5}
            speed={1400}
            freeMode={true}
            autoplay={{ delay: 1000 }}
            breakpoints={{
              0: {
                spaceBetween: 15, // Adjust this value for smaller screens
                slidesPerView: 1,
              },
              576: {
                spaceBetween: 40,
                slidesPerView: 2,
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 2,
              },
              992: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              1200: {
                spaceBetween: 60, // Adjust this value for larger screens
                slidesPerView: 4,
              },
              1440: {
                spaceBetween: 70, // Increase space for screens wider than 1440px
                slidesPerView: 4,
              },
            }}
            className="thm-swiper__slider swiper-container"
          >
            <div className="swiper-wrapper">
              {brandData.map((image, index) => (
                <SwiperSlide key={index} className="swiper-slide">
                  <Link to={process.env.PUBLIC_URL + '/'}>
                    <img src={publicUrl + 'assets/images/brand/' + image} alt="" />
                  </Link>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default BrandOne;
