import React from "react";
import HeaderThree from "../../common/header/HeaderThree";
import HeroThree from "../../components/hero/HeroThree";
import FeatureOne from "../../components/features/FeatureOne";
import ServiceOne from "../../components/services/ServiceOne";
import AboutOne from "../../components/about/AboutOne";
import TestimonialOne from "../../components/testimonial/TestimonialOne";
import BlogThree from "../../components/blog/BlogThree";
import BrandOne from "../../components/brand/BrandOne";
import FooterOne from "../../common/footer/FooterOne";
import ProjectOne from "../../components/project/ProjectOne";

const HomeThree = () => {
  return (
    <>
      <HeaderThree />
      <HeroThree />
      <FeatureOne />
      <ServiceOne />
      <AboutOne />
      <ProjectOne />
      <TestimonialOne />
      <BlogThree />
      <BrandOne />
      <FooterOne />
    </>
  );
};

export default HomeThree;
