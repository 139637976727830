import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getPortfolio } from "../../redux/features/portfolioSlice/portfolioSlice";

const ProjectFour = () => {
  const dispatch = useDispatch();
  const { portfolios: portfolioItemsFromRedux } = useSelector(
    (state) => state.portfolio
  );

  useEffect(() => {
    dispatch(getPortfolio());

    const $ = window.$;

    if ($(".img-popup").length) {
      var groups = {};
      $(".img-popup").each(function () {
        var id = parseInt($(this).attr("data-group"), 10);

        if (!groups[id]) {
          groups[id] = [];
        }

        groups[id].push(this);
      });

      $.each(groups, function () {
        $(this).magnificPopup({
          type: "image",
          closeOnContentClick: true,
          closeBtnInside: false,
          gallery: {
            enabled: true,
          },
        });
      });
    }
  }, [dispatch]);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <>
      <section className="project-one-sec project-one-sec--three">
        <div className="container">
          <div className="row">
            {portfolioItemsFromRedux.map((portfolioItem, index) => (
              <div
                key={index}
                className="col-xl-4 col-lg-4"
              >
                <div
                  className="project-one__single wow fadeInUp"
                  data-wow-delay={`${index * 200}ms`}
                  data-wow-duration="1500ms"
                >
                  <div className="project-one__single-img">
                    <div className="inner">
                      <img style={{ maxHeight: "300px" }}
                        src={portfolioItem.image.filePath}
                        alt={portfolioItem.image.fileName}
                      />
                      <div className="project-one__link">
                        <a
                          className="img-popup"
                          href={portfolioItem.image.filePath}
                        >
                          <span className="icon-plus-sign"></span>
                        </a>
                      </div>
                    </div>
                    <div className="content-box">
                      <h2>
                        <Link>
                          {portfolioItem.title}
                        </Link>
                      </h2>
                      <p>{portfolioItem.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectFour;
