import React from "react";
import { Link } from "react-router-dom";

const ServiceOne = () => {
  const publicUrl = process.env.PUBLIC_URL + "/";

  const services = [
    {
      title: "Water Infrastructure",
      link: "/water-infrastructure",
      image: "services-v1-img4.jpg",
      description:
        "Water infrastructure is vital for our daily lives. Explore Simandlovu's exceptional solutions that transform water infrastructure and meet the unique needs of our valued customers...",
    },
    {
      title: "Civil Engineering Works",
      link: "/civil-engineering-works",
      image: "services-v1-img6.jpg",
      description:
        "Explore cutting-edge civil engineering solutions and unmatched client support with Simandlovu. Committed to transforming lives and the nation...",
    },
    {
      title: "General Building Construction",
      link: "/general-building-construction",
      image: "services-v1-img5.jpg",
      description:
        "Discover dependable and knowledgeable construction services to turn your building project into reality...",
    },
  ];

  return (
    <>
      <section className="services-one-sec">
        <div className="container">
          <div className="sec-title text-center">
            <div className="sec-title__tagline">
              <span className="left"></span>
              <h6>Our Services</h6> <span className="right"></span>
            </div>
            <h2 className="sec-title__title">Our Professional Services</h2>
          </div>

          <div className="row">
            {services.map((service, index) => (
              <div key={index} className="col-xl-4 col-lg-4">
                <div className="services-one__single">
                  <div className="services-one__single-img">
                    <img
                      src={
                        publicUrl + `assets/images/services/${service.image}`
                      }
                      alt={service.title}
                    />
                  </div>

                  <div className="services-one__single-content">
                    <div className="services-one__single-content-title">
                      <h2>
                        <Link to={process.env.PUBLIC_URL + service.link}>
                          {service.title}
                        </Link>
                      </h2>
                    </div>

                    <div className="services-one__single-content-bottom">
                      <p>{service.description}</p>

                      <div className="services-one__single-btn">
                        <Link
                          to={process.env.PUBLIC_URL + service.link}
                          className="thm-btn"
                          data-text="Read More +"
                        >
                          Read More +
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceOne;
