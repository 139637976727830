// Define an array of team members with an id property
const teamMembers = [
    {
        id: 1,
        image: "team-v1-img12.jpg",
        name: "Mzwakhe Simamane",
        role: "Senior Worker"
    },
    {
        id: 2,
        image: "team-v1-img13.jpg",
        name: "Sibusiso Mngadi",
        role: "Senior Worker"
    },
    {
        id: 3,
        image: "team-v1-img12.jpg",
        name: "Mzwakhe Simamane",
        role: "Senior Worker"
    },
    {
        id: 4,
        image: "team-v1-img13.jpg",
        name: "Sibusiso Mngadi",
        role: "Senior Worker"
    },
    {
        id: 5,
        image: "team-v1-img12.jpg",
        name: "Mzwakhe Simamane",
        role: "Senior Worker"
    },
    {
        id: 6,
        image: "team-v1-img13.jpg",
        name: "Sibusiso Mngadi",
        role: "Senior Worker"
    },
    {
        id: 7,
        image: "team-v1-img12.jpg",
        name: "Mzwakhe Simamane",
        role: "Senior Worker"
    },
    {
        id: 8,
        image: "team-v1-img13.jpg",
        name: "Sibusiso Mngadi",
        role: "Senior Worker"
    }
];

export default teamMembers;
