import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlogDashboardHeader from "../../common/header/blogDashboardHeader";

import http from "../../services/blogService";
import Container from "react-bootstrap/Container";
//import ListGroup from "react-bootstrap/ListGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import { SpinnerImg } from "../../components/loader/Loader";
// utility function to format the creation date
import formatDate from "../../services/formDate";
import Card from "../../components/blog/card/Card";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import { getPosts } from "../../redux/features/blogSlice/postSlice";

const HomeDefault = () => {
  const dispatch = useDispatch();
  const { posts, isLoading, isError, message } = useSelector(
    (state) => state.post
  );

  useEffect(() => {
    dispatch(getPosts());
  }, [dispatch, isError, message]);

  return (
    <>
      <BlogDashboardHeader />
      <div>
        {isLoading && <SpinnerImg />}

        <Container className="my-5" style={{ maxWidth: "800px" }}>
          <Image
            src="avatar.jpeg"
            width="150"
            style={{ borderRadius: "50%" }}
            className="d-block mx-auto img-fluid"
          />
          <h2 className="text-center">Welcome to the Digital Marketing blog</h2>
        </Container>
        <Container style={{ maxWidth: "800px" }}>
          <ListGroup variant="flush" as="ol">
            {posts.map((post) => (
              <ListGroup.Item key={post._id}>
                <div className="fw-bold h3">
                  <Link
                    to={`/api/posts/${post._id}`}
                    style={{ textDecoration: "none" }}
                  >
                    {post.title}
                  </Link>
                </div>
                <Card cardClass="group">
                  {post?.image ? (
                    <img src={post.image.filePath} alt={post.image.fileName} />
                  ) : (
                    <p>No image set for this product</p>
                  )}
                </Card>
                <div
                  style={{ paddingTop: "5px", paddingBottom: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(post.content),
                  }}
                ></div>
                <div>
                  {post.author} -{" "}
                  <span className="text-secondary">
                    {formatDate(post.createdAt)}
                  </span>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Container>
      </div>
    </>
  );
};

export default HomeDefault;

// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import BlogDashboardHeader from "../../common/header/blogDashboardHeader";

// import http from "../../services/blogService";
// import Container from "react-bootstrap/Container";
// //import ListGroup from "react-bootstrap/ListGroup";
// import ListGroup from "react-bootstrap/ListGroup";
// import Image from "react-bootstrap/Image";
// import { SpinnerImg } from "../../components/loader/Loader";
// // utility function to format the creation date
// import formatDate from "../../services/formDate";
// import Card from "../../components/blog/card/Card";
// import DOMPurify from "dompurify";
// import { useDispatch, useSelector } from "react-redux";
// import { getPosts } from "../../redux/features/blogSlice/postSlice";

// const HomeDefault = () => {
//   const dispatch = useDispatch();

//   const { posts, isLoading, isError, message } = useSelector(
//     (state) => state.post
//   );
//   const postsFromRedux = posts;
//   useEffect(() => {
//     dispatch(getPosts());
//   }, [dispatch, isError, message]);

//   return (
//     <>
//       <BlogDashboardHeader />
//       <div>
//         {isLoading && <SpinnerImg />}

//         <Container className="my-5" style={{ maxWidth: "800px" }}>
//           <Image
//             src="avatar.jpeg"
//             width="150"
//             style={{ borderRadius: "50%" }}
//             className="d-block mx-auto img-fluid"
//           />
//           <h2 className="text-center">Welcome to the Digital Marketing blog</h2>
//         </Container>
//         <Container style={{ maxWidth: "800px" }}>
//           <ListGroup variant="flush" as="ol">
//             {postsFromRedux.map((post) => {
//               // Map the posts to JSX
//               return (
//                 <ListGroup.Item key={post._id}>
//                   <div className="fw-bold h3">
//                     <Link
//                       to={`/api/posts/${post._id}`}
//                       style={{ textDecoration: "none" }}
//                     >
//                       {post.title}
//                     </Link>
//                   </div>
//                   <Card cardClass="group">
//                     {post?.image ? (
//                       <img
//                         src={post.image.filePath}
//                         alt={post.image.fileName}
//                       />
//                     ) : (
//                       <p>No image set for this product</p>
//                     )}
//                   </Card>
//                   <div
//                     style={{ paddingTop: "5px", paddingBottom: "5px" }}
//                     dangerouslySetInnerHTML={{
//                       __html: DOMPurify.sanitize(post.content),
//                     }}
//                   ></div>
//                   <div>
//                     {post.author} -{" "}
//                     <span className="text-secondary">
//                       {formatDate(post.createdAt)}
//                     </span>
//                   </div>
//                 </ListGroup.Item>
//               );
//             })}
//           </ListGroup>
//         </Container>
//       </div>
//     </>
//   );
// };

// export default HomeDefault;
