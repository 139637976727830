import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getPortfolio } from "../../redux/features/portfolioSlice/portfolioSlice";

const ProjectOne = () => {
  const publicUrl = process.env.PUBLIC_URL + "/";
  const dispatch = useDispatch();
  const { portfolios: portfolioItemsFromRedux} = useSelector(
    (state) => state.portfolio
  ); // Replace 'projectItems' with your actual Redux state slice name

  // Local state to track whether data has been fetched
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (!portfolioItemsFromRedux.length) {
       // Fetch portfolio data only if it's not already available
       dispatch(getPortfolio());

       // Update the local state to indicate that data has been fetched
      // setDataFetched(true);
    }
    
    //  if (portfolioItemsFromRedux.length > 0) {
      const $ = window.$;
    
       
      function projectMasonryLayout() {
        if ($(".masonry-layout").length) {
          $(".masonry-layout").isotope({
            layoutMode: "masonry",
          });
        }
  
        if ($(".post-filter").length) {
          $(".post-filter li")
            .children(".filter-text")
            .on("click", function () {
              const Self = $(this);
              const selector = Self.parent().attr("data-filter");
              $(".post-filter li").removeClass("active");
              Self.parent().addClass("active");
              $(".filter-layout").isotope({
                filter: selector,
                animationOptions: {
                  duration: 500,
                  easing: "linear",
                  queue: false,
                },
              });
              return false;
            });
        }
      }
  
      if ($(".img-popup").length) {
        const groups = {};
        $(".img-popup").each(function () {
          const id = parseInt($(this).attr("data-group"), 10);
  
          if (!groups[id]) {
            groups[id] = [];
          }
  
          groups[id].push(this);
        });
  
        $.each(groups, function () {
          $(this).magnificPopup({
            type: "image",
            closeOnContentClick: true,
            closeBtnInside: false,
            gallery: {
              enabled: true,
            },
          });
        });
      //  }

       $(window).on("load", projectMasonryLayout);
     
      projectMasonryLayout();
    }
   
  }, [dispatch, portfolioItemsFromRedux]);


  return (
    <>
      <section className="project-one-sec">
        <div className="shape1">
          <img
            src={publicUrl + "assets/images/shapes/project-v1-shape1.png"}
            alt=""
          />
        </div>
        <div className="shape2">
          <img
            src={publicUrl + "assets/images/shapes/project-v1-shape2.png"}
            alt=""
          />
        </div>
        <div className="container-fluid">
          <div className="sec-title text-center">
            <div className="sec-title__tagline">
              <span className="left"></span>
              <h6>Our project</h6> <span className="right"></span>
            </div>
            <h2 className="sec-title__title">Our Working Project</h2>
          </div>

          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="project-one__menu-box">
                <ul className="project-filter clearfix post-filter has-dynamic-filters-counter">
                  <li data-filter=".filter-item" className="active">
                    <span className="filter-text">
                      <i className="icon-hammer"></i> All Project
                    </span>
                  </li>
                  <li data-filter=".water_infrastructure">
                    <span className="filter-text">
                      <i className="icon-wrench"></i> Water Infrastructure
                    </span>
                  </li>
                  <li data-filter=".building">
                    <span className="filter-text">
                      <i className="icon-hotel"></i> General Building
                    </span>
                  </li>
                  <li data-filter=".civil_eng_works">
                    <span className="filter-text">
                      <i className="icon-surgery"></i> CivilEng Works
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row filter-layout masonry-layout">
            {portfolioItemsFromRedux.map((item, index) => (
              <div
                key={index}
                className={`col-xl-3 col-lg-3 col-md-6 filter-item ${item.category}`}
              >
                <div className="project-one__single">
                  <div className="project-one__single-img">
                    <div className="inner">
                      <img src={item.image.filePath} alt={item.image.fileName} />
                      <div className="project-one__link">
                        <a className="img-popup" href={item.image.filePath}>
                          <span className="icon-plus-sign"></span>
                        </a>
                      </div>
                    </div>
                    <div className="content-box">
                      <h2>
                        <Link
                          to={process.env.PUBLIC_URL + "/portfolio-details"}
                        >
                          {item.title}
                        </Link>
                      </h2>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectOne;
