import React from "react";
import LogoWhite from "./LogoWhite";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";
import SearchButton from "./SearchButton";

const HeaderThree = () => {
  return (
    <>
      <header className="main-header main-header-one clearfix">
        <div className="main-header-one__wrapper">
          <div className="main-header-one__top">
            <div className="container-fluid">
              <div className="main-header-one__top-inner">
                <div className="main-header-one__left">
                  <LogoWhite />
                </div>

                <div className="main-header-one__middle">
                  <ul>
                    <li>
                      <div className="icon">
                        <span className="icon-pin"></span>
                      </div>

                      <div className="text">
                        <span>Our Location</span>
                        <p className="email">
                          <a>
                            6-8 Cobbleset Lane, New Germany <br /> Pinetown
                            Durban
                          </a>
                        </p>
                      </div>
                    </li>

                    <li>
                      <div className="icon">
                        <span className="icon-email"></span>
                      </div>

                      <div className="text">
                        <span>Online Support</span>
                        <p className="email">
                          <a href="mailto:tenders@simandlovu.co.za">
                            tenders@simandlovu.co.za
                          </a>
                        </p>
                      </div>
                    </li>

                    <li>
                      <div className="icon">
                        <span className="icon-phone-call"></span>
                      </div>

                      <div className="text">
                        <span>Phone</span>
                        <p className="number">
                          <a href="tel:0723922392">072 392 2392</a>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="main-header-one__right">
                  {/* <OffCanvasStyleOne /> THis is the three button thing with the hamburger logo*/}
                </div>
              </div>
            </div>
          </div>

          <div className="main-header-one__bottom">
            <div className="container-fluid">
              <div className="main-header-one__bottom-inner">
                <div className="main-header-one__bottom-left">
                  <nav className="main-menu main-menu--1">
                    <div className="main-menu__inner">
                      <MobileMenu />
                      <div className="stricky-one-logo">
                        <LogoWhite />
                      </div>
                      <Nav />
                    </div>
                  </nav>
                </div>

                <div className="main-header-one__bottom-right">
                  <div className="search-box">
                    <SearchButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderThree;
