import React from "react";

export const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <ul className="styled-pagination clearfix">
          <li className="prev" onClick={prevPage}>
            <div className="gen">
              <span className="fa fa-angle-double-left"></span>
            </div>
          </li>
          {pageNumbers.map((pgNumber) => (
            <li key={pgNumber} onClick={() => setCurrentPage(pgNumber)}>
              <div className={`gen ${currentPage == pgNumber ? "active" : ""}`}>
                {pgNumber}
              </div>
            </li>
          ))}
          <li className="next" onClick={nextPage}>
            <div className="gen">
              <span className="fa fa-angle-double-right"></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
