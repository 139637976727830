import React, { useEffect } from "react";

function EconomicGrowth() {
  useEffect(() => {
    const $ = window.$;

    if ($(".dial").length) {
      $(".dial").appear(
        function () {
          var elm = $(this);
          var color = elm.attr("data-fgColor");
          var perc = elm.attr("value");
          elm.knob({
            value: 0,
            min: 0,
            max: 100,
            skin: "tron",
            readOnly: true,
            thickness: 0.15,
            dynamicDraw: true,
            displayInput: false,
          });
          $({
            value: 0,
          }).animate(
            {
              value: perc,
            },
            {
              duration: 2000,
              easing: "swing",
              progress: function () {
                elm.val(Math.ceil(this.value)).trigger("change");
              },
            }
          );
          $(this).append(function () {});
        },
        {
          accY: 20,
        }
      );
    }

    if ($(".count-box").length) {
      $(".count-box").appear(
        function () {
          var $t = $(this),
            n = $t.find(".count-text").attr("data-stop"),
            r = parseInt($t.find(".count-text").attr("data-speed"), 10);

          if (!$t.hasClass("counted")) {
            $t.addClass("counted");
            $({
              countNum: $t.find(".count-text").text(),
            }).animate(
              {
                countNum: n,
              },
              {
                duration: r,
                easing: "linear",
                step: function () {
                  $t.find(".count-text").text(Math.floor(this.countNum));
                },
                complete: function () {
                  $t.find(".count-text").text(this.countNum);
                },
              }
            );
          }
        },
        {
          accY: 0,
        }
      );
    }
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <section className="work-perfomance-one-sec">
      <div className="container">
        <div className="row">
          {/* Start Work Perfomance One Content */}
          <div className="col-xl-7">
            <div className="work-perfomance-one__content">
              <div className="sec-title">
                <div className="sec-title__tagline">
                  <h6> Economic Growth</h6> <span className="right"></span>
                </div>
                <h2 className="sec-title__title">
                  Company Objectives
                </h2>
                <p className="sec-title__text">
                  Start a marketing campaign to highlight the services and high
                  caliber of services provided in order to draw in a large
                  number of businesses. Contribute significantly to urban
                  renewal initiatives by offering services and support to
                  initiatives within the province's townships. Become a valuable
                  provider of services, resources, and assistance. Create
                  connections with state-run construction and development
                  projects in South Africa.
                </p>
              </div>
              <div className="work-perfomance-one__content-bottom">
                <div className="progress-box">
                  <div className="inner-box">
                    <div className="graph-outer">
                      <input
                        type="text"
                        className="dial"
                        data-fgColor="#001659"
                        data-bgColor="#ebebeb"
                        data-width="100"
                        data-height="100"
                        data-linecap="normal"
                        value="100"
                      />
                      <div className="inner-text count-box">
                        <span
                          className="count-text"
                          data-stop="100"
                          data-speed="2000"
                        ></span>
                        <span className="count-Parsent">%</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-box">
                  <h2>Social Responsibility</h2>
                  <p>
                    Determine the unique requirements and difficulties that new
                    contractors face so that the company can make the most of
                    its resources and skills to find solutions and other
                    options. Create connections with rural and semi-rural
                    communities to start the process of planning the human and
                    material resources needed for community development
                    initiatives. Become a significant player in the construction
                    industry (social responsibility) by offering the local
                    communities high-quality company supplies, equipment, and
                    related services.
                  </p>
                </div>
              </div>
            </div>
            {/* End Work Perfomance One Content */}
          </div>

          {/* Start Work Perfomance One Img */}
          <div className="col-xl-5">
            <div className="work-perfomance-one__img">
              <img
                src={
                  publicUrl +
                  "assets/images/resources/economic-growth-v1-img2.jpg"
                }
                alt=""
              />
            </div>
          </div>
          {/* Start Work Perfomance One Img */}
        </div>
      </div>
      {/* Rest of the component JSX */}
    </section>
  );
}

export default EconomicGrowth;
