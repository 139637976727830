import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Nav = () => {
  const location = useLocation();
  useEffect(() => {
    const $ = window.$;
    // Get the current URL
    const currentUrl = location.pathname;

    // Find the link that matches the current URL
    $(".main-menu__list li a").each(function () {
      const linkUrl = $(this).attr("href");

      console.log(currentUrl);

      // Check if the current URL contains the link URL
      if (currentUrl !== "#") {
        if (currentUrl === linkUrl) {
            // Add the "current" class to the parent list item
            $(this).closest("li").addClass("current");
          }
      }
    });
  }, [location.pathname]);

  return (
    <div className="main-menu text-center">
      <nav>
        <ul className="main-menu__list">
          <li>
            <Link to={process.env.PUBLIC_URL + `/`}>Home</Link>
            
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + `/about-one`}>About Us</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + `/services`}>Services</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + `/portfolio`}>Portfolio</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + `/team`}>Team</Link>
          </li>
          <li className="dropdown">
            <Link to={process.env.PUBLIC_URL + `/blog-standard`}>News</Link>
            {/* <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/blog`}>Blog</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/blog-grid`}>
                  Blog Grid
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/blog-list`}>
                  Blog List
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/blog-standard`}>
                  Blog Standard
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/blog-details`}>
                  Blog Details
                </Link>
              </li>
            </ul> */}
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + `/contact`}>Contact</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Nav;
