import axios from "axios";
import { toast } from "react-toastify";

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const domain =
  process.env.NODE_ENV === "production" ? "" : "http://localhost:5000";

const http = async (url, { method = "GET", data = undefined }) => {
  return await axios({
    url: `${domain}${url}`,
    method,
    data,
  });
};

//Main functions to handle types of endpoints
const get = (url, opts = {}) => http(url, { ...opts });
const post = (url, opts = {}) => http(url, { method: "POST", ...opts });
const put = (url, opts = {}) => http(url, { method: "PUT", ...opts });
const deleteData = (url, opts = {}) => http(url, { method: "DELETE", ...opts });

const methods = {
  get,
  post,
  put,
  delete: deleteData,
};

export default methods;
