import React, { useEffect } from "react";

const WhyChooseOne = () => {
  useEffect(() => {
    const $ = window.$;

    if ($(".video-popup").length) {
      $(".video-popup").magnificPopup({
        type: "iframe",
        mainClass: "mfp-fade",
        removalDelay: 160,
        preloader: true,
        fixedContentPos: false,
      });
    }
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <section className="why-choose-one-sec">
      <div className="container">
        <div className="row">
          {/* Start Why Choose One Content */}
          <div className="col-xl-6">
            <div className="why-choose-one__content">
              <div className="sec-title">
                <div className="sec-title__tagline">
                  <h6> Why Choose Us </h6> <span className="right"></span>
                </div>
                <h2 className="sec-title__title">Simandlovu Construction:  Committed to Excellent Service</h2>
                <p className="sec-title__text">
                  At Simandlovu, we follow a path marked by principle and values, propelled by a mentality to deliver excellent
                  service, quality, and usher in a fresh tomorrow
                  characterized by unity.
                </p>
              </div>

              <div className="why-choose-one__content-top">
                <div className="img-box">
                  <img
                    src={publicUrl + "assets/images/resources/4963_01.jpg"}
                    alt=""
                  />
                </div>
                <div className="text-box">
                  <p>
                    Quality, we believe, is the foundation of any successful
                    project. Our dedication to using high-quality materials
                    and cutting-edge construction techniques ensures that
                    your project will stand the test of time. Our top
                    priorities are your satisfaction and the durability of
                    our work.
                  </p>
                </div>
              </div>

              <div className="why-choose-one__content-bottom">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="why-choose-one__content-bottom-single">
                      <h2>Warranty</h2>
                      <p>
                        Simandlovu Trading takes pride in the work we do and
                        stands behind our final product. In the event that
                        something goes wrong, we are here to help.
                      </p>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="why-choose-one__content-bottom-single">
                      <h2>Exceptional Service</h2>
                      <p>
                        We urge our clients to keep the lines of
                        communication open and to ask questions. Keeping
                        you, the client, updated makes it easier to go from
                        one phase to the next, which is crucial for a
                        successful project overall and happy clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Why Choose One Content */}

          {/* Start Why Choose One Img */}
          <div className="col-xl-6">
            <div className="why-choose-one__img">
              <div className="row">
                {/* Start Why Choose One Img Single */}
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="why-choose-one__img-single">
                    <div className="img-box">
                      {/* <img
                        src={publicUrl + "assets/images/resources/why-choose-v1-img7.jpg"}
                        alt=""
                      /> */}
                      <img
                        src={publicUrl + "assets/images/resources/4963_03.jpg"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                {/* End Why Choose One Img Single */}
                {/* Start Why Choose One Img Single */}
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="why-choose-one__img-single">
                    <div className="img-box">
                      <img
                        src={publicUrl + "assets/images/resources/4963_04.jpg"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                {/* End Why Choose One Img Single */}
                {/* Start Why Choose One Img Single */}
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="why-choose-one__img-single">
                    <div className="img-box">
                      <img
                        src={publicUrl + "assets/images/resources/4963_01.jpg"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                {/* End Why Choose One Img Single */}
                {/* Start Why Choose One Img Single */}
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="why-choose-one__img-single">
                    <div className="img-box">
                      <img
                        src={publicUrl + "assets/images/resources/4963_02.jpg"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                {/* End Why Choose One Img Single */}
              </div>
            </div>
          </div>
          {/* End Why Choose One Img */}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseOne;
