import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
  const { heading, currentPage, Img } = props;
  console.log(Img);
  const publicUrl = process.env.PUBLIC_URL;
  const currentPageValue = currentPage ? currentPage : heading;
  const imgSrc = Img ? Img : "page-header-img.jpg";

  return (
    <section className="page-header">
      <div
        className="page-header__bg"
        style={{
          backgroundImage: `url(${publicUrl}assets/images/backgrounds/${imgSrc})`,
        }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="page-header__wrapper">
              <div className="page-header__content">
                <h2>{heading}</h2>
                <div className="page-header__menu">
                  <ul>
                    <li>
                      <Link to={publicUrl + `/`}>Home</Link>
                    </li>
                    <li>{currentPageValue}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumb;
