import React from "react";
import PolicyPrivate from "../components/legal-info/PrivacyPolicy/PolicyPrivate";
import HeaderThree from "../common/header/HeaderThree";
import FooterOne from "../common/footer/FooterOne";

const PrivacyPolicy = () => {
  return (
    <>
        <HeaderThree />
        <PolicyPrivate />
        <FooterOne />
    </>
  );
};

export default PrivacyPolicy;
