import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  createPost,
  selectIsLoading,
} from "../../redux/features/blogSlice/postSlice";
import Loader from "../../components/loader/Loader";
import PostForm from "../../components/blog/postForm/PostForm";
import { EditorState } from "draft-js";
// import { useForm } from "react-hook-form";
// import Container from "react-bootstrap/Container";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import http from "../../services/blogService";

const initialState = {
  title: "",
  author: "",
  services: "",
};

const BlogCreation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [post, setPost] = useState(initialState);
  const [postImage, setPostImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [content, setContent] = useState(() => EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState(null);

  const isLoading = useSelector(selectIsLoading);

  const { title, author, services } = post;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
  };

  const handleImageChange = (e) => {
    setPostImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const savePost = async (e) => {
    e.preventDefault();
    console.log(postImage);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("author", author);
    formData.append("services", services);
    formData.append("content", convertedContent);
    formData.append("image", postImage);

    await dispatch(createPost(formData));

    navigate("/home-blog");
  };

  // const { register, handleSubmit } = useForm();

  // const onSubmit = async ({ title, services, author,content }) => {
  //   const payload = {
  //     title,
  //     author,
  //     services,
  //     tags: tags.split(",").map((tag) => tag.trim()),
  //     content,
  //   };
  //   await http.post("/api/posts", { data: payload });
  //   navigate("/home");
  // };

  return (
    // <Container className="my-5" style={{ maxWidth: "800px" }}>
    //   <h1>Create new Post</h1>
    //   <Form onSubmit={handleSubmit(onSubmit)} className="my-5">
    //     <Form.Group className="mb-3">
    //       <Form.Label>Title</Form.Label>
    //       <Form.Control
    //         type="text"
    //         placeholder="Enter title"
    //         {...register("title")}
    //       />
    //     </Form.Group>
    //     <Form.Group className="mb-3">
    //       <Form.Label>Services</Form.Label>
    //       <Form.Control
    //         type="text"
    //         placeholder="Enter Service"
    //         {...register("services")}
    //       />
    //       <Form.Text className="text-muted">
    //         Enter them separately them with ","
    //       </Form.Text>
    //     </Form.Group>
    //     <Form.Group className="mb-3">
    //       <Form.Label>Author</Form.Label>
    //       <Form.Control
    //         type="text"
    //         placeholder="Enter author"
    //         {...register("author")}
    //       />
    //     </Form.Group>
    //     <Form.Group className="mb-3">
    //       <Form.Label>Content</Form.Label>
    //       <Form.Control
    //         as="textarea"
    //         rows={3}
    //         placeholder="Your content..."
    //         {...register("content")}
    //       />
    //     </Form.Group>

    //     <Button variant="primary" type="submit">
    //       Publish
    //     </Button>
    //   </Form>
    //   <Link to="/home" style={{ textDecoration: "none" }}>
    //     &#8592; Back to Home
    //   </Link>
    // </Container>

    <div>
      <h3>Add New Post</h3>
      <PostForm
        post={post}
        imagePreview={imagePreview}
        content={content}
        setContent={setContent}
        convertedContent={convertedContent}
        setConvertedContent={setConvertedContent}
        handleInputChange={handleInputChange}
        handleImageChange={handleImageChange}
        savePost={savePost}
      />
    </div>
  );
};

export default BlogCreation;
