import React from "react";
import { Link } from "react-router-dom";

export default class FeatureFive extends React.Component {
  componentDidMount() {
    const $ = window.$;

    if ($(".tabs-box").length) {
      $(".tabs-box .tab-buttons .tab-btn").on("click", function (e) {
        e.preventDefault();
        var target = $($(this).attr("data-tab"));

        if ($(target).is(":visible")) {
          return false;
        } else {
          target
            .parents(".tabs-box")
            .find(".tab-buttons")
            .find(".tab-btn")
            .removeClass("active-btn");
          $(this).addClass("active-btn");
          target
            .parents(".tabs-box")
            .find(".tabs-content")
            .find(".tab")
            .fadeOut(0);
          target
            .parents(".tabs-box")
            .find(".tabs-content")
            .find(".tab")
            .removeClass("active-tab");
          $(target).fadeIn(300);
          $(target).addClass("active-tab");
        }
      });
    }

    if ($(".feature-three__history-carousel").length) {
      $(".feature-three__history-carousel").owlCarousel({
        loop: false,
        margin: 30,
        nav: false,
        smartSpeed: 500,
        autoHeight: false,
        autoplay: true,
        dots: false,
        autoplayTimeout: 6000,
        navText: [
          '<i className="fa fa-angle-double-left" aria-hidden="true"></i>',
          '<i className="fa fa-angle-double-right" aria-hidden="true"></i>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          992: {
            items: 2,
          },
          1200: {
            items: 3,
          },
          1350: {
            items: 3,
          },
        },
      });
    }
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <section className="feature-three-sec clearfix">
          <div
            className="feature-three__bg"
            style={{
              backgroundImage:
                "url(" +
                publicUrl +
                "assets/images/backgrounds/features-v1-bg5.jpg)",
            }}
          ></div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="feature-three__title">
                  <div className="sec-title">
                    <div className="sec-title__tagline">
                      <h6>More About Us</h6> <span className="right"></span>
                    </div>
                    <h2 className="sec-title__title">
                      Engineering Design Construction
                      <br />
                      Culture{" "}
                    </h2>
                    <p className="sec-title__text">
                      Since 2006, we have established a reputation for being
                      dedicated to being the best while also growing the
                      country's economy.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="feature-three__tab tabs-box">
                  <div className="feature-three__tab-button">
                    <ul className="tab-buttons clearfix">
                      <li data-tab="#history" className="tab-btn active-btn">
                        <h3>Our Business Ethics</h3>
                      </li>
                      <li data-tab="#growth " className="tab-btn">
                        <h3>Our Growth</h3>
                      </li>
                      <li data-tab="#mission" className="tab-btn">
                        <h3>Our Mission</h3>
                      </li>
                      <li data-tab="#vision" className="tab-btn">
                        <h3>Our Vision</h3>
                      </li>
                    </ul>
                  </div>

                  <div className="tabs-content">
                    {/* Start Tab */}
                    <div className="tab active-tab" id="history">
                      <div className="feature-three__tab-content-item">
                        <div className="feature-three__history-carousel owl-carousel owl-theme">
                          {/* Start Feature Three History Single */}
                          <div className="feature-three__history-single">
                            <div className="history-text">
                              <h2>Ethics</h2>
                            </div>
                            <div className="date-box">
                              <h2>Commitment to excellent service</h2>
                            </div>
                            <p>
                              A commitment to excellent service is at the core
                              of our values. We pledge to consistently deliver
                              exceptional services while maintaining the highest
                              standards of quality. Our dedication to meeting
                              and exceeding customer expectations sets us apart.
                            </p>
                          </div>
                          {/* End Feature Three History Single */}

                          {/* Start Feature Three History Single */}
                          <div className="feature-three__history-single">
                            <div className="history-text">
                              <h2>Ethics</h2>
                            </div>
                            <div className="date-box">
                              <h2>
                                Pride in the Provision of Quality Products
                              </h2>
                            </div>
                            <p>
                              We take pride in providing top-quality products to
                              clients. Our commitment to using the best
                              materials and cutting-edge techniques ensures the
                              durability of our work. Customer satisfaction and
                              quality are our top priorities.
                            </p>
                          </div>
                          {/* End Feature Three History Single */}

                          {/* Start Feature Three History Single */}
                          <div className="feature-three__history-single">
                            <div className="history-text">
                              <h2>Ethics</h2>
                            </div>
                            <div className="date-box">
                              <h2>
                                Establish Lasting Links with our Market and
                                Stakeholders
                              </h2>
                            </div>
                            <p>
                              Building lasting relationships with our market and
                              stakeholders is a fundamental part of our business
                              ethics. We believe in open communication and
                              collaboration, ensuring mutual success.
                            </p>
                          </div>
                          {/* End Feature Three History Single */}

                          {/* Start Feature Three History Single */}
                          <div className="feature-three__history-single">
                            <div className="history-text">
                              <h2>Ethics</h2>
                            </div>
                            <div className="date-box">
                              <h2>
                                Skills Development as a Part of Company Growth
                                and Development
                              </h2>
                            </div>
                            <p>
                              We are committed to the growth and development of
                              our employees. Skills development is a cornerstone
                              of our company, ensuring that our team is always
                              at their best to deliver top-quality work.
                            </p>
                          </div>
                          {/* End Feature Three History Single */}

                          {/* Start Feature Three History Single */}
                          <div className="feature-three__history-single">
                            <div className="history-text">
                              <h2>Ethics</h2>
                            </div>
                            <div className="date-box">
                              <h2>
                                Honest and Transparent Operation (Sticking to
                                Rules, Regulations, and Standards)
                              </h2>
                            </div>
                            <p>
                              Honesty and transparency in our operations are key
                              values. We strictly adhere to rules, regulations,
                              and standards, ensuring that our business is
                              conducted ethically and responsibly.
                            </p>
                          </div>
                          {/* End Feature Three History Single */}

                          {/* Start Feature Three History Single */}
                          <div className="feature-three__history-single">
                            <div className="history-text">
                              <h2>Ethics</h2>
                            </div>
                            <div className="date-box">
                              <h2>
                                Friendly and Warm Service (Putting the Customer
                                First)
                              </h2>
                            </div>
                            <p>
                              We are known for our friendly and warm customer
                              service. Putting the customer first is our
                              priority. We strive to make your experience with
                              us as pleasant as possible.
                            </p>
                          </div>
                          {/* End Feature Three History Single */}
                        </div>
                      </div>
                    </div>
                    {/* End Tab */}

                    {/* Start Tab */}
                    <div className="tab" id="growth">
                      <div className="feature-three__tab-content-item">
                        <div className="tab-content-inner">
                          <div className="row">
                            <div className="col-xl-5 col-lg-6">
                              <div className="tab-content-inner-text">
                                <h2>Our Growth</h2>
                                <p>
                                  There are numerous hurdles in business that
                                  might stymie growth and success. However, the
                                  latest Covid19 outbreak has created a problem
                                  that requires adaptation and fortitude. We
                                  have had to adapt all of our conventions and
                                  business practices as the world has changed,
                                  and at Simandlovu, we have confronted this
                                  problem full on and revised our processes to
                                  suit the company vision and mission. There
                                  will be many hurdles ahead, but our beliefs
                                  and principles will see us through.
                                </p>
                              </div>
                            </div>
                            <div className="col-xl-7 col-lg-6">
                              <div className="tab-content-inner-img">
                                <img
                                  src={
                                    publicUrl +
                                    "assets/images/resources/feature-v3-img6.png"
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Tab */}

                    {/* Start Tab */}
                    <div className="tab" id="mission">
                      <div className="feature-three__tab-content-item">
                        <div className="tab-content-inner">
                          <div className="row">
                            <div className="col-xl-5 col-lg-6">
                              <div className="tab-content-inner-text">
                                <h2>Our Mission</h2>
                                <p>
                                  Simandlovu Trading is dedicated to providing
                                  exceptional construction services with a
                                  strong emphasis on quality, safety, and client
                                  satisfaction. We commit to fostering growth
                                  and development, nurturing skills, and
                                  maintaining honesty and transparency in all
                                  our operations. Our mission is to make a
                                  positive impact on our community and national
                                  assets.
                                </p>
                              </div>
                            </div>
                            <div className="col-xl-7 col-lg-6">
                              <div className="tab-content-inner-img">
                                <img
                                  style={{ maxHeight: "600px" }}
                                  src={
                                    publicUrl +
                                    "assets/images/resources/feature-v3-img9.jpg"
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Tab */}

                    {/* Start Tab */}
                    <div className="tab" id="vision">
                      <div className="feature-three__tab-content-item">
                        <div className="tab-content-inner">
                          <div className="row">
                            <div className="col-xl-5 col-lg-6">
                              <div className="tab-content-inner-text">
                                <h2>Our Vision</h2>
                                <p>
                                  Our vision at Simandlovu Trading is to be a
                                  leading force in civil and building
                                  construction. We aim to consistently deliver
                                  beyond expectations, focusing on superior
                                  quality, competitive pricing, and safe working
                                  conditions. We aspire to create lasting links
                                  with our market, drive skills development, and
                                  remain committed to excellence in all that we
                                  do.
                                </p>
                              </div>
                            </div>
                            <div className="col-xl-7 col-lg-6">
                              <div className="tab-content-inner-img">
                                <img
                                  style={{ maxHeight: "600px" }}
                                  src={
                                    publicUrl +
                                    "assets/images/resources/feature-v3-img8.jpg"
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Tab */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
