import React from 'react';
import HeaderThree from "../../common/header/HeaderThree";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import BrandOne from '../../components/brand/BrandOne';
import FooterOne from '../../common/footer/FooterOne';
import SingleCivilEngineeringWorks from '../../components/single-service/SingleCivilEngineeringWorks';


const CivilEngineeringWorks = () => {
  return (
    <>
      <HeaderThree />
      <Breadcrumb
        heading="Civil Engineering Works"
        currentPage="Civil Engineering Works"
        Img="civil-engineering-works-bg-img.jpg"
      />
      <SingleCivilEngineeringWorks /> 
      <BrandOne />
      <FooterOne />
    </>
  );
};

export default CivilEngineeringWorks;
