import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const HeroThree = () => {
  useEffect(() => {
    const $ = window.$;

    if ($(".slider-carousel").length) {
      $(".slider-carousel").owlCarousel({
        animateOut: "fadeOut",
        animateIn: "fadeIn",
        loop: true,
        margin: 0,
        nav: false,
        singleItem: true,
        smartSpeed: 500,
        autoplay: true,
        autoplayTimeout: 6000,
        navText: [
          '<span class="icon-right-arrow"></span>',
          '<span class="icon-right-arrow"></span>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1024: {
            items: 1,
          },
        },
      });
    }
  }, []);

  const publicUrl = process.env.PUBLIC_URL + "/";

  const sliderOneData = [
    {
      image: publicUrl + "assets/images/slider/slider-v1-img4.jpg",
      title: "Inspired Construction.",
      subTitle: "Your Dreams Our Expertiser",
    },
    {
      image: publicUrl + "assets/images/slider/slider-v1-img5.jpg",
      title: "Inspired Construction.",
      subTitle: "Industry Tailored Solutions",
    },
    {
      image: publicUrl + "assets/images/slider/slider-v1-img6.jpg",
      title: "Inspired Construction.",
      subTitle: "We Produce Robust Construction",
    },
  ];

  return (
    <section className="slider-one-sec">
      <div className="slider-carousel owl-carousel owl-theme">
        {sliderOneData.map((slide, index) => (
          <div className="slider-one__single" key={index}>
            <div
              className="image-layer"
              style={{
                backgroundImage: `url(${slide.image})`,
              }}
            ></div>
            <div className="container">
              <div className="row clearfix">
                <div className="col-xl-12">
                  <div className="slider-one__single-content">
                    <h3 style={{ letterSpacing: ".4rem" }}>{slide.title}</h3>
                    <h2>{slide.subTitle}</h2>
                    <div className="btn-box">
                      <Link
                        to={process.env.PUBLIC_URL + `/about-one`}
                        className="thm-btn"
                        data-text="Read More +"
                      >
                        Read More +
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HeroThree;
