import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import BlogCategory from "../blog/BlogCategory";
import BlogLatestPost from "../blog/BlogLatestPost";
import BlogQuery from "../blog/BlogQuery";
import BlogSearch from "../blog/BlogSearch";
import BlogTags from "../blog/BlogTags";
import { useDispatch, useSelector } from "react-redux";
import { getSinglePost } from "../../redux/features/blogSlice/postSlice";
import formatDate from "../../services/formDate";
import DOMPurify from "dompurify";

const SingleBlogDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getSinglePost(id));
  }, [id, dispatch]);

  //Code to destructure my current state
  const { post, isLoading, isError, message } = useSelector(
    (state) => state.post
  );

  const postFromRedux = post || {};
  const servicesFromRedux = postFromRedux.services || [];

  console.log(servicesFromRedux);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7">
              <div className="blog-details__left">
                <div className="blog-details__img">
                  {postFromRedux?.image ? (
                    <img
                      src={postFromRedux.image.filePath}
                      alt={postFromRedux.image.fileName}
                    />
                  ) : (
                    <p>No image set for this post</p>
                  )}
                  <div
                    className="blog-details__date"
                    style={{ opacity: "80%" }}
                  >
                    {formatDate(postFromRedux.createdAt)}
                  </div>
                </div>
                <div className="blog-details__content">
                  <ul className="blog-details__meta">
                    <li>
                      <Link to={process.env.PUBLIC_URL + `/`}>
                        <i className="fa fa-bookmark"></i>
                        <span>{servicesFromRedux[2]}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + `/`}>
                        <i className="fa fa-user"></i>
                        <span>{postFromRedux.author} </span>
                      </Link>
                    </li>
                  </ul>
                  <div
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(post.content),
                    }}
                  ></div>
                </div>
                <div className="blog-details__bottom">
                  <p className="blog-details__tags">
                    <Link to={process.env.PUBLIC_URL + `/water-infrastructure`}>
                      Water Infrastructure
                    </Link>
                    <Link to={process.env.PUBLIC_URL + `/civil-engineering-works`}>
                      Civil Enginereering Works 
                    </Link>
                    <Link to={process.env.PUBLIC_URL + `/general-building-construction`}>
                      General Building
                    </Link>
                  </p>
                  <div className="blog-details__social-list blog-details__social-list_SinglePost">
                    <a href="https://twitter.com/">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="https://facebook.com/">
                      <i className="fab fa-facebook"></i>
                    </a>
                    <a href="https://pinterest.com/">
                      <i className="fab fa-pinterest-p"></i>
                    </a>
                    <a href="https://instagram.com/">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Start Sidebar */}
            <div className="col-xl-5 col-lg-5">
              <div className="sidebar">
                <BlogSearch />
                {/* <BlogCategory /> */}
                {/* <BlogLatestPost /> */}
                <BlogQuery />
                <BlogTags />
              </div>
            </div>
            {/* End Sidebar */}
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleBlogDetails;
