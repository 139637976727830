import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBridgeWater,
  faBuilding,
  faHome,
  faRoad,
  faSchool,
  faWater,
} from "@fortawesome/free-solid-svg-icons";

const cardMenu = [
  {
    icon: (
      <FontAwesomeIcon
        icon={faHome}
        bounce
        size="2xl"
        style={{ color: "#9cbb1e" }}
      />
    ),

    title: "MUNICIPAL OFFICES",
    content: `The construction sector operates in a constantly evolving environment where adoption of new technology is imperative. At Simandlovu, we have upheld our culture and vision to overcome obstacles by adhering to our core principles and values and by never compromising who we are.
      `,
  },
  {
    icon: (
      <FontAwesomeIcon icon={faWater} size="2xl" style={{ color: "#5b5b5b" }} />
    ),
    title: "CONSTRUCTION OF CONCRETE PIPES",
    content: `Rehabilitation and the building of concrete pipes are the areas of expertise for Simandlovu. We install HDPE, UPVC, and small to large diameter steel pipelines with competence and a solid reputation for dependability. Ensuring long-lasting and effective solutions is our top priority.
      `,
  },
  {
    icon: (
      <FontAwesomeIcon
        icon={faSchool}
        bounce
        size="2xl"
        style={{ color: "#9cbb1e" }}
      />
    ),
    title: "EDUCATIONAL INITIATIVES",
    content: `Our commitment lies in influencing the future via exceptional Sports facilities and educational initiatives. Our school renovations demonstrate our dedication to community development and promote a happier, livelier tomorrow."
      `,
  },
  {
    icon: (
      <FontAwesomeIcon
        icon={faBuilding}
        size="2xl"
        style={{ color: "#5b5b5b" }}
      />
    ),
    title: "MULTI-STORY STRUCTURES",
    content: `Well-known for our proficiency in building multi-story structures, cutting-edge community centers, and ablution restrooms is Simandlovu, a top construction company. Exquisite resorts are another area of our work; here, we specialize in both new construction and renovation, producing structures of the highest caliber.`,
  },
  {
    icon: (
      <FontAwesomeIcon
        icon={faBridgeWater}
        bounce
        size="2xl"
        style={{ color: "#9cbb1e" }}
      />
    ),
    title: "WATER-PIPELINE EXPERTS",
    content: `Thanks to our extensive experience in water services, 
    Simandlovu is leading the charge in guaranteeing a sustainable future. 
    In addition to offering excellent water reticulation services, 
    we are experts at installing, removing, and moving water pipelines. 
    Our dedication to excellence guarantees the smooth delivery of water services for an improved future.`,
  },
  {
    icon: (
      <FontAwesomeIcon icon={faRoad} size="2xl" style={{ color: "#5b5b5b" }} />
    ),
    title: "ROADS AND CIVIL",
    content: `Simandlovu is committed to finishing projects on time and under budget. Our expertise lies in building and enhancing roads, bridges, tunnels, and embankments. We make sure your needs for infrastructure are not just satisfied, but beyond.
    `,
  },
];

export default cardMenu;
