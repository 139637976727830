import React from "react";

// Import the Navbar, Nav and Container components from Bootstrap for a nice layout
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

import "bootstrap/dist/css/bootstrap.min.css";



const blogDashboardHeader = () => {
  return (
    <>
    <div>
      <Navbar bg="dark" expand="lg" variant="dark">
        <Container>
          <Navbar.Brand href="/home">My Blog</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Nav className="me-auto">
            <Nav.Link href="/blog-create">New</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      </div>
    </>
  );
};

export default blogDashboardHeader;
