import React from 'react';
import { useNavigate } from 'react-router-dom';

const BlogQuery = () => {
   let publicUrl = process.env.PUBLIC_URL + '/';
  const navigate = useNavigate()  
  const navigateContact = () => {
    navigate(`${process.env.PUBLIC_URL}/contact`)
  }
 

  return (
    <>
      <div className="sidebar__single sidebar__support">
        <div
          className="sidebar__support-bg"
          style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/sidebar-support-bg.jpg)' }}
        ></div>
        <h3 className="sidebar__suppot-title">Have Any Query?</h3>
        <p className="sidebar__suppot-text">
          <span>"When something is important enough, you do it even if the odds are not in you favour"</span> <br/>
          <span>Elon Musk</span>
        </p>
        <div className="sidebar__support-btn-box">
          <button onClick={navigateContact} className="thm-btn" data-text="Send Message +" type="submit" data-loading-text="Please wait...">
            Send Message +
          </button>
        </div>
      </div>
    </>
  );
};

export default BlogQuery;
