import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./PostForm.scss";
import Card from "../../blog/card/Card";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";

const PostForm = ({
  post,
  imagePreview,
  content,
  setContent,
  convertedContent,
  setConvertedContent,
  handleInputChange,
  handleImageChange,
  savePost,
}) => {
  useEffect(() => {
    let html = convertToHTML(content.getCurrentContent());
    setConvertedContent(html);
  }, [content]);

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  return (
    <div className="add-product">
      <Card cardClass={"card"}>
        <form onSubmit={savePost}>
          <Card cardClass={"group"}>
            <label>Post Image</label>
            <code className="--color-dark">
              Supported Formats: jpg, jpeg, png
            </code>
            <input
              type="file"
              name="image"
              onChange={(e) => handleImageChange(e)}
            />
            {imagePreview != null ? (
              <div className="image-preview">
                <img src={imagePreview} alt="post" />
              </div>
            ) : (
              <p>No image set for this product </p>
            )}
          </Card>

          <label>Post Title</label>
          <input
            type="text"
            placeholder="Post Title"
            name="title"
            value={post?.title}
            onChange={handleInputChange}
          />

          <label>Post Services:</label>
          <input
            type="text"
            placeholder="Post Services"
            name="services"
            value={post?.services}
            onChange={handleInputChange}
          />

          <label>Post Author</label>
          <input
            type="text"
            placeholder="Post Author"
            name="author"
            value={post?.author}
            onChange={handleInputChange}
          />
          <label>Post Content:</label>
          <Editor
            editorState={content}
            onEditorStateChange={setContent}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
              options: ["inline", "blockType"],
            }}
            hashtag={{
              separator: " ",
              trigger: "#",
            }}
            mention={{
              separator: " ",
              trigger: "@",
              suggestions: [
                { text: "JavaScript", value: "javascript", url: "js" },
                { text: "Golang", value: "golang", url: "go" },
              ],
            }}
          />
          <div
            className="preview"
            dangerouslySetInnerHTML={createMarkup(convertedContent)}
          ></div>

          <div>
            <button
              type="submit"
              style={{ border: "2px solid", marginTop: "20px" }}
            >
              Save Post
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default PostForm;
