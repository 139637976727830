import React from 'react';
import HeaderThree from "../../common/header/HeaderThree";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import BrandOne from '../../components/brand/BrandOne';
import FooterOne from '../../common/footer/FooterOne';
import SingleWaterInfrastructure from '../../components/single-service/SingleWaterInfrustracture';

const WaterInfrastructure = () => {
  return (
    <>
      <HeaderThree />
      <Breadcrumb
        heading="Water Infrastructure"
        currentPage="Water Infrastructure"
        Img="water_infrastructure-bg-img.jpg"
      />
      <SingleWaterInfrastructure /> 
      <BrandOne />
      <FooterOne />
    </>
  );
};

export default WaterInfrastructure;
