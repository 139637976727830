import React from "react";

export default class WhyChooseFour extends React.Component {
  componentDidMount() {
    const $ = window.$;

    if ($(".count-bar").length) {
      $(".count-bar").appear(
        function () {
          var el = $(this);
          var percent = el.data("percent");
          $(el).css("width", percent).addClass("counted");
        },
        {
          accY: -50,
        }
      );
    }

    if ($(".circle-progress").length) {
      $(".circle-progress").appear(function () {
        let circleProgress = $(".circle-progress");
        circleProgress.each(function () {
          let progress = $(this);
          let progressOptions = progress.data("options");
          progress.circleProgress(progressOptions);
        });
      });
    }
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <section
          className="why-choose-two-sec why-choose-two-sec--services"
          style={{ marginTop: "5px", marginBottom: "90px" }}
        >
          <div
            className="shape1 wow slideInLeft"
            data-wow-delay="100ms"
            data-wow-duration="2500ms"
          >
            <img
              src={publicUrl + "assets/images/shapes/why-choose-v2-shape1.png"}
              alt=""
            />
          </div>
          <div className="container">
            <div className="row">
              {/* Start Why Choose Two Content */}
              <div className="col-xl-6">
                <div className="why-choose-two__content">
                  <div className="sec-title">
                    <div className="sec-title__tagline">
                      <h6>Why Choose Us </h6> <span className="right"></span>
                    </div>
                    <h2
                      className="sec-title__title"
                      style={{ fontSize: "18px", lineHeight: "30px" }}
                    >
                      Simandlovu Construction fuels South Africa's sustained
                      growth and well-being. Our diverse expertise in
                      construction ensures excellence in both major, complex
                      projects and smaller endeavors, consistently exceeding
                      client expectations.{" "}
                    </h2>
                    <p className="sec-title__text">
                      We bring together a unique blend of building and civil
                      engineering expertise, allowing us to provide top-tier
                      solutions across a wide spectrum of building, water, and
                      civil projects. Our commitment lies in collaborative
                      partnerships that not only benefit our people but also
                      create shared value. We're dedicated to delivering
                      innovative projects that consistently exceed client
                      expectations while staying within budget and on schedule.
                      <br/>
                      <br/>
                      Based in Durban, Simandlovu has a rich history dating back
                      to 2006, marked by its substantial contributions to the
                      construction industry in South Africa. Our legacy includes
                      the development of major roads in rural areas and iconic
                      buildings in Durban. At the core of Simandlovu
                      Construction is a leadership team deeply committed to
                      delivering value to clients through a collaborative
                      partnership approach. We aspire to be the preferred
                      contractor of choice, offering world-class solutions for
                      the betterment of our people. With an exemplary health and
                      safety record, Simandlovu Construction is proudly
                      accredited with ISO 9001, 14001, and ISO 45001
                      certifications.{" "}
                    </p>
                  </div>

                  <div className="why-choose-two__content-progress"></div>
                </div>
              </div>
              {/* Start Why Choose Two Img */}
              <div className="col-xl-6">
                <div
                  className="why-choose-two__img"
                  style={{
                    backgroundImage:
                      "url(" +
                      publicUrl +
                      "assets/images/resources/feature-v3-img5.jpg)",
                  }}
                ></div>
              </div>
              {/* End Why Choose Two Img */}
            </div>
          </div>
        </section>
      </>
    );
  }
}
