import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getPosts } from "../../redux/features/blogSlice/postSlice";
import formatDate from "../../services/formDate";

const BlogThree = () => {
  const dispatch = useDispatch();
  const { posts, isLoading, isError, message } = useSelector(
    (state) => state.post
  );
  const postsFromRedux = posts;

  useEffect(() => {
    dispatch(getPosts());
  }, [dispatch, isError, message]);

  const recentPosts = postsFromRedux.slice(0, 3);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <>
      <section className="blog-one-sec">
        <div className="container">
          <div className="sec-title text-center">
            <div className="sec-title__tagline">
              <span className="left"></span>
              <h6>Our Blog</h6> <span className="right"></span>
            </div>
            <h2 className="sec-title__title">Our Latest News</h2>
          </div>
          <div className="row">
            {/* Start Blog One Single */}
            {recentPosts.map((post) => {
              return (
                <div
                  className="col-xl-4 col-lg-4 wow animated fadeInUp"
                  data-wow-delay="0.1s"
                  key={post._id}
                >
                  <div className="blog-one__single">
                    <div className="blog-one__single-img">
                      <div className="inner">
                        {post?.image ? (
                          <img
                            src={post.image.filePath}
                            alt={post.image.fileName}
                          />
                        ) : (
                          <p>No image set for this post</p>
                        )}
                      </div>
                      <div className="blog-one__single-content">
                        <div className="date-box text-center">
                          <span>{formatDate(post.createdAt)}</span>
                        </div>
                        <div className="inner-content">
                          <ul className="meta-info">
                            <li>
                              <span className="icon-account"></span>
                              <Link to={process.env.PUBLIC_URL + `/blog-details/${post._id}`}>
                                {post.author}
                              </Link>
                            </li>
                          </ul>
                          <h2>
                            <Link to={process.env.PUBLIC_URL + `/blog-details/${post._id}`}>
                            {post.title}
                            </Link>
                          </h2>
                          <div className="btn-box">
                            <Link to={process.env.PUBLIC_URL + `/blog-details/${post._id}`}>
                              Read More{" "}
                              <span className="icon-right-arrow-1"></span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* End Blog One Single */}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogThree;
