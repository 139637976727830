import React from 'react';

const BlogSearch = () => {
  return (
    <>
      <div className="sidebar__single sidebar__search">
        <form className="sidebar__search-form">
          <input type="search" placeholder="Search..." />
          <button type="submit" onClick={(e) => {e.preventDefault()}}><i className="fa fa-search"></i></button>
        </form>
      </div>
    </>
  );
};

export default BlogSearch;
