import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const FooterOne = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <>
      <footer className="footer-one-sec">
        <div
          className="footer-one__pattern"
          style={{
            backgroundImage:
              "url(" +
              publicUrl +
              "assets/images/pattern/footer-v1-pattern.png)",
          }}
        ></div>
        <div className="footer-one__top">
          <div
            className="footer-one__top-img"
            style={{
              backgroundImage:
                "url(" +
                publicUrl +
                "assets/images/resources/footer-v1-img6.png",
            }}
          ></div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-one__top-inner">
                  <div className="row">
                    <div
                      className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp"
                      data-wow-delay="0.1s"
                    >
                      <div className="footer-widget__column footer-widget__about">
                        <div className="footer-widget__about-logo">
                          <Link to="/">
                            <img
                              src={
                                publicUrl +
                                "assets/images/resources/simandlovu-logo.png"
                              }
                              alt="Logo"
                            />
                          </Link>
                        </div>

                        <p className="footer-widget__about-text">
                          We effortlessly instill a mentality that allows our
                          team members to give the greatest solutions to the
                          market by establishing our culture of economic
                          success.
                        </p>

                        <div className="footer-widget__about-social-link">
                          <ul>
                            <li>
                              <a href="https://www.facebook.com/people/Simandlovu-Trading/100057796564331/">
                                <span className="first icon-facebook-app-symbol"></span>
                                <span className="second icon-facebook-app-symbol"></span>
                              </a>
                            </li>

                            <li>
                              <a>
                                <span className="first icon-twitter"></span>
                                <span className="second icon-twitter"></span>
                              </a>
                            </li>

                            <li>
                              <a>
                              
                                <span className="fab fa-instagram"></span>
                              </a>
                            </li>

                            <li>
                              <a>
                                <span className="first icon-linkedin"></span>
                                <span className="second icon-linkedin"></span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp"
                      data-wow-delay="0.3s"
                    >
                      <div className="footer-widget__column footer-widget__services">
                        <h2 className="footer-widget__title">Useful Links</h2>
                        <ul className="footer-widget__services-list">
                          <li className="footer-widget__services-list-item">
                            <Link to="/about-one">About Us</Link>
                          </li>
                          <li className="footer-widget__services-list-item">
                            <Link to="/services">Services</Link>
                          </li>
                          <li className="footer-widget__services-list-item">
                            <Link to="/blog-standard">News</Link>
                          </li>
                          <li className="footer-widget__services-list-item">
                            <Link to="/portfolio">Portfolio</Link>
                          </li>
                          <li className="footer-widget__services-list-item">
                            <Link to="/contact">Get in Touch</Link>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp"
                      data-wow-delay="0.5s"
                    >
                      <div className="footer-widget__column footer-widget__explore">
                        <h2 className="footer-widget__title">Contact</h2>
                        <ul className="footer-widget__explore-list">
                          <li className="footer-widget__explore-list-item">
                            <Link>
                              Phone: <br /> 072 392 2392
                            </Link>
                          </li>
                          <li className="footer-widget__explore-list-item">
                            <Link
                              onClick={(e) => {
                                window.location.href =
                                  "mailto:tenders@simandlovu.co.za";
                              }}
                            >
                              Email: tenders@simandlovu.co.za
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp"
                      data-wow-delay="0.7s"
                    >
                      <div className="footer-widget__column footer-widget__posts">
                        <h2 className="footer-widget__title">
                          {" "}
                          Location! and News
                        </h2>
                        <div className="footer-widget__posts-list">
                          <ul>
                            <li>
                              <div className="img-box">
                                <img
                                  src={
                                    publicUrl +
                                    "assets/images/resources/footer-v1-img7.jpg"
                                  }
                                  alt=""
                                />
                                <div className="overlay-icon">
                                  <Link
                                    to={process.env.PUBLIC_URL + `/contact`}
                                  >
                                    <span className="icon-link"></span>
                                  </Link>
                                </div>
                              </div>

                              <div className="text-box">
                                <p>
                                  <Link>
                                    Address: <br />
                                    6-8 Cobbleset Lane, New Germany Pinetown
                                    Durban 3610
                                  </Link>
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-one__bottom clearfix">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-one__bottom-inner">
                  <div className="footer-one__bottom-text">
                    <p>
                      Copyright &copy;2023. All rights reserved to{" "}
                      <Link to="/">Simandlovu</Link>
                    </p>
                  </div>

                  <div className="footer-one__bottom-list">
                    <ul>
                      <li>
                        <Link to="/terms-and-conditions">Terms & Condition </Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
