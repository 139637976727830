import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ButtonBox from "./ButtonBox";
import ServiceContactBox from "./ServiceContactBox";
import ServiceSearch from "./ServiceSearch";

const SingleCivilEngineeringWorks = () => {
  useEffect(() => {
    const $ = window.$;

    if ($(".tabs-box").length) {
      $(".tabs-box .tab-buttons .tab-btn").on("click", function (e) {
        e.preventDefault();
        var target = $($(this).attr("data-tab"));

        if ($(target).is(":visible")) {
          return false;
        } else {
          target
            .parents(".tabs-box")
            .find(".tab-buttons")
            .find(".tab-btn")
            .removeClass("active-btn");
          $(this).addClass("active-btn");
          target
            .parents(".tabs-box")
            .find(".tabs-content")
            .find(".tab")
            .fadeOut(0);
          target
            .parents(".tabs-box")
            .find(".tabs-content")
            .find(".tab")
            .removeClass("active-tab");
          $(target).fadeIn(300);
          $(target).addClass("active-tab");
        }
      });
    }
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <>
      <section className="services-details-page">
        <div className="container">
          <div className="row">
            {/* Start Services Details Content */}
            <div className="col-xl-7 col-lg-7">
              <div className="services-details__content">
                {/* Rest of your content */}
                <div className="services-details__img1">
                  <img
                    src={
                      publicUrl +
                      "assets/images/services/services-details-img6.jpg"
                    }
                    alt=""
                  />
                </div>
                <div className="services-details__text-box1">
                  <div className="title">
                    <h2>Civil Engineering Works</h2>
                  </div>
                  <p className="text-1">
                    Simandlovu takes pride in our Civil Engineering Works
                    expertise. We have successfully completed numerous civil
                    engineering projects with years of experience and a
                    commitment to excellence, contributing to the development
                    and infrastructure enhancement of our communities.{" "}
                  </p>

                  <p className="text-2">
                    We are also committed to securing our projects at
                    competitive prices, providing safe working conditions, and
                    delivering quality work in a timely manner while meeting the
                    needs of our clients.{" "}
                  </p>
                </div>

                <div className="services-details__text-box2">
                  <div className="row">
                    <div className="col-xl-5">
                      <div className="services-details__text-box2-img">
                        <img
                          src={
                            publicUrl +
                            "assets/images/services/services-details-img12.jpg"
                          }
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="col-xl-7">
                      <div className="services-details__text-box2-content">
                        <div className="title">
                          <h2 style={{ marginBottom: "20px" }}>We provide:</h2>
                        </div>
                        {/* <div className="text">
                          <p>
                            Duis aute irure dolor in reprehenderit in voluptate
                            velit esse cillum dolore eu fugiat{" "}
                          </p>
                        </div> */}
                        <ul>
                          <li>
                            <div className="icon">
                              <span className="icon-tick"></span>
                            </div>

                            <div className="text">
                              <p>Construction of Roads, embankments</p>
                            </div>
                          </li>

                          <li>
                            <div className="icon">
                              <span className="icon-tick"></span>
                            </div>

                            <div className="text">
                              <p>Construction of tunnels, and bridges</p>
                            </div>
                          </li>

                          <li>
                            <div className="icon">
                              <span className="icon-tick"></span>
                            </div>

                            <div className="text">
                              <p>Upgrading of Roads, and Paving. </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="services-details__text-box3">
                  <p>
                    We promise to deliver every project the highest degree of
                    precision and careful attention to every detail in addition
                    to a variety of other services. We understand that the
                    success of a project depends on every component, no matter
                    how tiny. Even the toughest aspects of your project will be
                    completed flawlessly because to our commitment to
                    perfection.{" "}
                  </p>

                  <ul>
                    <li>
                      <div className="icon">
                        <span className="icon-tick"></span>
                      </div>

                      <div className="text">
                        <p>
                          Construction of small and large steel pipelines, UPVC,
                          HDPE
                        </p>
                      </div>
                    </li>

                    <li>
                      <div className="icon">
                        <span className="icon-tick"></span>
                      </div>

                      <div className="text">
                        <p>
                          The building of concrete pipes and their
                          rehabilitation.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                {/* Start Services Details Tab Box */}
                <div className="services-details__tab-box">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="services-details__tab tabs-box">
                        <div className="services-details__tab-button">
                          <ul className="tab-buttons clearfix">
                            <li
                              data-tab="#materials"
                              className="tab-btn active-btn"
                            >
                              <h4>Quality Materials</h4>
                            </li>
                            <li data-tab="#design " className="tab-btn">
                              <h4>Tailored Solutions</h4>
                            </li>
                            <li data-tab="#care" className="tab-btn">
                              <h4>Personal-Care</h4>
                            </li>
                            {/* <li data-tab="#support" className="tab-btn">
                              <h4>Super Support</h4>
                            </li> */}
                          </ul>
                        </div>

                        <div className="tabs-content">
                          {/* Start Tab */}
                          <div className="tab active-tab" id="materials">
                            <div className="services-details__tab-content-item">
                              <div className="quality-materials-tab-box">
                                <div className="row">
                                  <div className="col-xl-6">
                                    <div className="quality-materials-tab-box-img">
                                      <img
                                        src={
                                          publicUrl +
                                          "assets/images/services/services-details-img7.jpg"
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </div>

                                  <div className="col-xl-6">
                                    <div className="quality-materials-tab-box-content">
                                      <p className="text1">
                                        The foundation of our work is quality.
                                        When it comes to the quality of
                                        materials and workmanship, we will not
                                        compromise.
                                      </p>

                                      <p className="text2">
                                        {" "}
                                        Our team relentlessly pursues the
                                        highest quality standards to produce
                                        results that stand the test of time.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* End Tab */}

                          {/* Start Tab */}
                          <div className="tab" id="design">
                            <div className="services-details__tab-content-item">
                              <div className="interior-design-tab-box">
                                <div className="row">
                                  <div className="col-xl-6">
                                    <div className="interior-design-tab-box-img">
                                      <img
                                        src={
                                          publicUrl +
                                          "assets/images/services/services-details-img10.jpg"
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </div>

                                  <div className="col-xl-6">
                                    <div className="interior-design-tab-box-content">
                                      <p className="text1">
                                        The degree of difficulty varies from
                                        project to project, and we approach each
                                        endeavor with a fresh perspective.
                                      </p>

                                      <p className="text2">
                                        We tailor our solutions to the specific
                                        needs and challenges of your project,
                                        ensuring the best possible results.{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* End Tab */}

                          {/* Start Tab */}
                          <div className="tab" id="care">
                            <div className="services-details__tab-content-item">
                              <div className="personal-care-tab-box">
                                <div className="row">
                                  <div className="col-xl-6">
                                    <div className="personal-care-tab-box-img">
                                      <img
                                        src={
                                          publicUrl +
                                          "assets/images/services/services-details-img11.jpg"
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </div>

                                  <div className="col-xl-6">
                                    <div className="personal-care-tab-box-content">
                                      <p className="text1">
                                        Everything we do revolves around our
                                        clients. We collaborate with you to
                                        determine your goals, objectives, and
                                        expectations.
                                      </p>

                                      <p className="text2">
                                        This collaborative approach enables us
                                        to precisely tailor our services to your
                                        needs.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* End Tab */}

                          {/* Start Tab */}
                          {/* <div className="tab" id="support">
                            <div className="services-details__tab-content-item">
                              <div className="super-support-tab-box">
                                <div className="row">
                                  <div className="col-xl-12">
                                    <div className="super-support-tab-box-form">
                                      <form
                                        action="/assets/inc/sendemail.php"
                                        className="comment-one__form contact-form-validated"
                                        novalidate="novalidate"
                                      >
                                        <div className="row">
                                          <div className="col-xl-6 col-lg-6">
                                            <div className="comment-form__input-box">
                                              <input
                                                type="text"
                                                placeholder="Full name"
                                                name="name"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-xl-6 col-lg-6">
                                            <div className="comment-form__input-box">
                                              <input
                                                type="email"
                                                placeholder="Email address"
                                                name="email"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-xl-12 col-lg-12">
                                            <div className="comment-form__input-box">
                                              <textarea
                                                name="message"
                                                placeholder="Your Message"
                                              ></textarea>
                                            </div>
                                            <button
                                              className="thm-btn comment-form__btn"
                                              data-text="Send Message +"
                                              type="submit"
                                              data-loading-text="Please wait..."
                                            >
                                              Send Message +
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {/* End Tab */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Services Details Tab Box */}
              </div>
            </div>
            {/* End Services Details Content */}

            {/* Start Services Details Sidebar */}
            <div className="col-xl-5 col-lg-5">
              <div className="services-details__sidebar">
                {/* <ServiceSearch /> */}

                {/* Rest of your sidebar content */}
                {/* Start Services Details Sidebar Single */}
                <div className="services-details__sidebar-single services-details-category">
                  <div className="title">
                    <h2>Services Category</h2>
                  </div>
                  <ul className="services-details-category-list">
                    <li>
                      <Link
                        to={process.env.PUBLIC_URL + `/water-infrastructure`}
                      >
                        Water Infrastructure
                        <span className="icon-right-arrow"></span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={process.env.PUBLIC_URL + `/civil-engineering-works`}
                        className="active"
                      >
                        Civil Engineering Works
                        <span className="icon-right-arrow"></span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          `/general-building-construction`
                        }
                      >
                        General Building Construction
                        <span className="icon-right-arrow"></span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to={process.env.PUBLIC_URL + `/architecture-design`}
                      >
                        Architecture Design
                        <span className="icon-right-arrow"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + `/multistory-build`}>
                        Multistory Build
                        <span className="icon-right-arrow"></span>
                      </Link>
                    </li> */}
                  </ul>
                </div>
                {/* End Services Details Sidebar Single */}

                <ButtonBox />
                <ServiceContactBox />
              </div>
            </div>
            {/* End Services Details Sidebar */}
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleCivilEngineeringWorks;
