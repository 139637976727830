import React from "react";
import { Link } from "react-router-dom";

export default class ServiceContactBox extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <div className="services-details__sidebar-single services-details-contact-box">
          <div className="title">
            <h2>Get in Touch</h2>
          </div>
          <p>
            Let's discuss how we can bring your vision to life while ensuring
            the greatest return on your investment. At Simandlovu, we don't just
            build structures; we build legacies, one project at a time."{" "}
          </p>
          <div className="number">
            <Link to={process.env.PUBLIC_URL + `/contact`}>
              Contact Us  
              <span className="icon-right-arrow" style={{ marginLeft: "5px" }}></span>
            </Link>
          </div>
        </div>
      </>
    );
  }
}
