import React from 'react';
import {Link} from 'react-router-dom';

export default class BlogTags extends React.Component {
    render(){
        return (
            <>
                <div className="sidebar__single sidebar__tags">
                    <h3 className="sidebar__title">Tags Cloud</h3>
                    <ul className="sidebar__tags-list">
                        <li><Link>Project</Link></li>
                        <li><Link>Design</Link></li>
                        <li><Link>Art</Link></li>
                        <li><Link>illustration</Link></li>
                        <li><Link>Love</Link></li>
                    </ul>
                </div>
            </>
        )
    }
}