import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import postService from "./postService";
import { toast } from "react-toastify";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const initialState = {
  post: {},
  posts: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  error: undefined,
};

// actions
export const createPost = createAsyncThunk(
  "posts/create",
  async (formData, thunkAPI) => {
    try {
      return await postService.createPost(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(`this where the error is coming from`);
      return `${thunkAPI.rejectWithValue(message)} this the error`;
    }
  }
);

//Get all posts
export const getPosts = createAsyncThunk(
  "posts/getAll",
  async (_, thunkAPI) => {
    try {
      return await postService.getPosts();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get a single post
export const getSinglePost = createAsyncThunk(
  "posts/getSinglePost",
  async (id, thunkAPI) => {
    try {
      return await postService.getSinglePost(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const postSlice = createSlice({
  name: "post",
  initialState,
  extraReducers: (builder) => {
    //handle actions
    //pending -> fulfilled -> rejected
    builder
      .addCase(createPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.posts.push(action.payload);
        toast.success("Post added successfully");
      })
      .addCase(createPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.success("Post added successfully");
      })
      .addCase(getPosts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.posts = action.payload.data.posts;
        // console.log(state.posts)
      })
      .addCase(getPosts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(getSinglePost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSinglePost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        //payload is dat coming from db
        state.post = action.payload.data.post;
      })
      .addCase(getSinglePost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const selectIsLoading = (state) => state.post.isLoading;
export const selectPost = (state) => state.post.post;

//Generate reducer
export default postSlice.reducer;
