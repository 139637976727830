import React from 'react'
import HeaderThree from '../common/header/HeaderThree'
import Terms from '../components/legal-info/terms-of-use/terms'
import FooterOne from '../common/footer/FooterOne'

const TermsOfUse = () => {
  return (
    <>
        <HeaderThree/>
        <Terms/>
        <FooterOne />
    </>
  )
}

export default TermsOfUse