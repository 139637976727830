import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import WOW from "wowjs";
import { FaAngleUp } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Pages
import HomeDefault from "./pages/blog/HomeDefault";
import HomeThree from "./pages/home/HomeThree";
import AboutOne from "./pages/about/AboutOne";
import ServicesPage from "./pages/services/ServicesPage";
import CivilEngineeringWorks from "./pages/services/CivilEngineeringWorks";
import GeneralBuildingConstruction from "./pages/services/GeneralBuildingConstruction";
import WaterInfrastructure from "./pages/services/WaterInfrastructure";
import PortfolioPage from "./pages/PortfolioPage";
import TeamPage from "./pages/TeamPage";
import TeamDetails from "./pages/TeamDetails";
import BlogStandardPage from "./pages/blog/BlogStandardPage";
import BlogDetails from "./pages/blog/BlogDetails";
import ContactPage from "./pages/ContactPage";
import Error from "./pages/Error";
import ScrollToTopRoute from "./components/scroll-to-top-route/ScrollToTopRoute";

import BlogCreation from "./pages/blog/BlogCreation";
import BlogPost from "./pages/blog/BlogPost";
import BlogEdit from "./pages/blog/BlogEdit";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";

function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <Router>
      <ToastContainer />
      <ScrollToTopRoute />
      <Routes>
        <Route
          path={`${process.env.PUBLIC_URL}/`}
          exact
          element={<HomeThree />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home-blog`}
          exact
          element={<HomeDefault />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/about-one`}
          exact
          element={<AboutOne />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/services`}
          exact
          element={<ServicesPage />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/water-infrastructure`}
          exact
          element={<WaterInfrastructure />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/civil-engineering-works`}
          exact
          element={<CivilEngineeringWorks />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/general-building-construction`}
          exact
          element={<GeneralBuildingConstruction />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/portfolio`}
          exact
          element={<PortfolioPage />}
        />

        <Route
          path={`${process.env.PUBLIC_URL}/team`}
          exact
          element={<TeamPage />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/team-details`}
          exact
          element={<TeamDetails />}
        />

        <Route
          path={`${process.env.PUBLIC_URL}/blog-standard`}
          exact
          element={<BlogStandardPage />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-details/:id`}
          exact
          element={<BlogDetails />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/api/posts/:id`}
          exact
          element={<BlogPost />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/api/posts/:id/edit`}
          exact
          element={<BlogEdit />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-create`}
          exact
          element={<BlogCreation />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/contact`}
          exact
          element={<ContactPage />}
        />
        <Route path={`${process.env.PUBLIC_URL}/privacy-policy`} exact element={<PrivacyPolicy />} />
        <Route path={`${process.env.PUBLIC_URL}/terms-and-conditions`} exact element={<TermsOfUse />} />
        <Route path={`${process.env.PUBLIC_URL}/*`} exact element={<Error />} />
      </Routes>
      <ScrollToTop
        className="scrollUp"
        smooth
        top="1500"
        component={<FaAngleUp />}
      />
    </Router>
  );
}

export default App;
