import React from "react";

export default class ContactMap extends React.Component {
  render() {
    return (
      <>
        <div className="contact-page-google-map">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <iframe
                  title="Address"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2214.796212769134!2d28.0537253!3d-26.1856948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef6fefaaaaaaaab%3A0xc4cc2d7008864757!2sSimandlovu%20Trading%2C%206%20Cobbleset%20Ln%2C%20Pinetown%2C%20New%20Germany%2C%203600!5e0!3m2!1sen!2sza!4v1635848034516!5m2!1sen!2sza"
                  className="contact-page-google-map__one"
                  allowFullScreen
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
