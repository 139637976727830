import React from "react";
import HeaderThree from "../../common/header/HeaderThree";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import WhyChooseFour from "../../components/why-choose-us/WhyChooseFour";
import FeatureTwo from "../../components/features/FeatureTwo";
import ProjectOne from "../../components/project/ProjectOne";
import BrandOne from "../../components/brand/BrandOne";
import FooterOne from "../../common/footer/FooterOne";
import ServiceOne from "../../components/services/ServiceOne";
import TestimonialOne from "../../components/testimonial/TestimonialOne";

const ServicesPage = () => {
  return (
    <>
      <HeaderThree />
      <Breadcrumb heading="Services" currentPage="Services" Img="page-header-services-img.jpg" />

      <div
        className="quote-container"
        style={{
          backgroundColor: "white",
          padding: "20px",
          marginTop: "40px",
          marginLeft: "50%",
        }}
      >
        <p
          className="quote-text"
          style={{
            fontFamily: "Shadows Into Light",
            fontSize: "30px",
            color: "blue",
          }}
        >
          "It always seems impossible until it's done" - Nelson Mandela
        </p>
      </div>
      <WhyChooseFour />
      <ServiceOne />
      <ProjectOne />
      <div style={{ marginBottom: "50px" }}>
       <TestimonialOne />
      </div>
      <ServiceOne />
      <BrandOne />
      <FooterOne />
    </>
  );
};

export default ServicesPage;
