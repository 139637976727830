import React from "react";

import styled from "styled-components";

const Container = styled.div`
  max-width: 800px;
  margin: 25px auto;
  padding: 20px;
`;

const Heading1 = styled.h1`
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 20px;
`;

const Heading2 = styled.h2`
  font-size: 1.5rem;
  color: #007bff;
  margin-top: 20px;
`;

const Heading3 = styled.h3`
  font-size: 1.2rem;
  color: #007bff;
  margin-top: 15px;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
`;

const UnorderedList = styled.ul`
  padding-left: 20px;
`;

const Anchor = styled.a`
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const PolicyPrivate = () => {
  return (
    <Container>
      <Heading1>Privacy Policy</Heading1>
      <Paragraph>Last updated: November 16, 2023</Paragraph>
      <Paragraph>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </Paragraph>
      <Paragraph>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </Paragraph>
      <Heading2>Interpretation and Definitions</Heading2>
      <Heading3>Interpretation</Heading3>
      <Paragraph>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </Paragraph>
      <Heading3>Definitions</Heading3>
      <Paragraph>For the purposes of this Privacy Policy:</Paragraph>
      <UnorderedList>
        <li>
          <Paragraph>
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            &quot;control&quot; means ownership of 50% or more of the shares,
            equity interest or other securities entitled to vote for election of
            directors or other managing authority.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Agreement) refers to Simandlovu trading CC, 6-8 Cobbleset Lane,
            New Germany,Pinetown, Durban.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>Cookies</strong> are small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            the details of Your browsing history on that website among its many
            uses.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>Country</strong> refers to:the Republic of South Africa
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>Service</strong> refers to the Website.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>Website</strong> refers to Simandlovu, accessible from{" "}
            <a
              href="https://www.simandlovu.co.za"
              rel="external nofollow noopener"
              target="_blank"
            >
              https://www.simandlovu.co.za
            </a>
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </Paragraph>
        </li>
      </UnorderedList>
      <Heading2>Collecting and Using Your Personal Data</Heading2>
      <Heading3>Types of Data Collected</Heading3>
      <h4>Personal Data</h4>
      <Paragraph>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </Paragraph>
      <UnorderedList>
        <li>
          <Paragraph>Email address</Paragraph>
        </li>
        <li>
          <Paragraph>First name and last name</Paragraph>
        </li>
        <li>
          <Paragraph>Phone number</Paragraph>
        </li>
        <li>
          <Paragraph>Usage Data</Paragraph>
        </li>
      </UnorderedList>
      <h4>Tracking Technologies and Cookies</h4>
      <Paragraph>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. The technologies We use may include:
      </Paragraph>
      <UnorderedList>
        <li>
          <strong>Cookies or Browser Cookies.</strong> A cookie is a small file
          placed on Your Device. You can instruct Your browser to refuse all
          Cookies or to indicate when a Cookie is being sent. However, if You do
          not accept Cookies, You may not be able to use some parts of our
          Service. Unless you have adjusted Your browser setting so that it will
          refuse Cookies, our Service may use Cookies.
        </li>
        <li>
          <strong>Web Beacons.</strong> Certain sections of our Service and our
          emails may contain small electronic files known as web beacons (also
          referred to as clear gifs, pixel tags, and single-pixel gifs) that
          permit the Company, for example, to count users who have visited those
          pages or opened an email and for other related website statistics (for
          example, recording the popularity of a certain section and verifying
          system and server integrity).
        </li>
      </UnorderedList>
      <Paragraph>
        Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
        Persistent Cookies remain on Your personal computer or mobile device
        when You go offline, while Session Cookies are deleted as soon as You
        close Your web browser.
      </Paragraph>
      <Heading3>Use of Your Personal Data</Heading3>
      <Paragraph>
        The Company may use Personal Data for the following purposes:
      </Paragraph>
      <UnorderedList>
        <li>
          <Paragraph>
            <strong>To provide and maintain our Service</strong>, including to
            monitor the usage of our Service.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>To contact You:</strong> To contact You by email, telephone
            calls, SMS, or other equivalent forms of electronic communication,
            such as a mobile application's push notifications regarding updates
            or informative communications related to the functionalities,
            products or contracted services, including the security updates,
            when necessary or reasonable for their implementation.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>To manage Your requests:</strong> To attend and manage Your
            requests to Us.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>For business transfers:</strong> We may use Your information
            to evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of Our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Data held by Us about our Service users is among the assets
            transferred.
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            <strong>For other purposes</strong>: We may use Your information for
            other purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
          </Paragraph>
        </li>
      </UnorderedList>
      <Paragraph>
        We may share Your personal information in the following situations:
      </Paragraph>
      <UnorderedList>
        <li>
          <strong>With Service Providers:</strong> We may share Your personal
          information with Service Providers to monitor and analyze the use of
          our Service, to contact You.
        </li>
        <li>
          <strong>For business transfers:</strong> We may share or transfer Your
          personal information in connection with, or during negotiations of,
          any merger, sale of Company assets, financing, or acquisition of all
          or a portion of Our business to another company.
        </li>
        <li>
          <strong>With Affiliates:</strong> We may share Your information with
          Our affiliates, in which case we will require those affiliates to
          honor this Privacy Policy. Affiliates include Our parent company and
          any other subsidiaries, joint venture partners or other companies that
          We control or that are under common control with Us.
        </li>
        <li>
          <strong>With Your consent</strong>: We may disclose Your personal
          information for any other purpose with Your consent.
        </li>
      </UnorderedList>
      <Heading3>Retention of Your Personal Data</Heading3>
      <Paragraph>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </Paragraph>
      <Paragraph>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </Paragraph>
      <Heading3>Transfer of Your Personal Data</Heading3>
      <Paragraph>
        Personal Information processed by us will not be routed/transferred to a
        third country or International organization.
      </Paragraph>
      <Paragraph>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located.
      </Paragraph>
      <Paragraph>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </Paragraph>
      <Paragraph>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        unless there are adequate controls in place including the security of
        Your data and other personal information.
      </Paragraph>
      <Heading3>Delete Your Personal Data</Heading3>
      <Paragraph>
        You have the right to delete or request that We assist in deleting the
        Personal Data that We have collected about You.
      </Paragraph>
      <Paragraph>
        You may also contact Us to request access to, correct, or delete any
        personal information that You have provided to Us.
      </Paragraph>
      <Paragraph>
        Please note, however, that We may need to retain certain information
        when we have a legal obligation or lawful basis to do so.
      </Paragraph>
      <Heading3>Disclosure of Your Personal Data</Heading3>
      <h4>Business Transactions</h4>
      <Paragraph>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </Paragraph>
      <h4>Law enforcement</h4>
      <Paragraph>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </Paragraph>
      <h4>Other legal requirements</h4>
      <Paragraph>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </Paragraph>
      <UnorderedList>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of the Company</li>
        <li>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          Protect the personal safety of Users of the Service or the public
        </li>
        <li>Protect against legal liability</li>
      </UnorderedList>
      <Heading3>Security of Your Personal Data</Heading3>
      <Paragraph>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </Paragraph>
      <Paragraph>
        We will notify you if we become aware of any unauthorised use,
        disclosure or processing of your personal information;
      </Paragraph>
      <Heading2>Children's Privacy</Heading2>
      <Paragraph>
        If you are under 18 years of age (child), we will require the consent of
        your parent/guardian/competent person before we process such personal
        information.
      </Paragraph>
      <Heading2>Links to Other Websites</Heading2>
      <Paragraph>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </Paragraph>
      <Paragraph>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </Paragraph>
      <Heading2>Your Rights</Heading2>
      <Paragraph>You have the right at any time to:</Paragraph>
      <UnorderedList>
        
        <li>object to the processing of Personal Information (subject to legislation);</li>

        <li>lodge a complaint with the company.</li>
      </UnorderedList>
      <Heading2>Changes to this Privacy Policy</Heading2>
      <Paragraph>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </Paragraph>
      <Paragraph>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the &quot;Last
        updated&quot; date at the top of this Privacy Policy.
      </Paragraph>
      <Paragraph>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </Paragraph>
      <Heading2>Contact Us</Heading2>
      <Paragraph>
        If you have any questions about this Privacy Policy, You can contact us:
      </Paragraph>
      <UnorderedList>
        <li>
          <Paragraph>
            By visiting this page on our website:{" "}
            <Anchor
              href="https://simandlovu.com"
              rel="external nofollow noopener"
              target="_blank"
            >
              https://simandlovu.com
            </Anchor>
          </Paragraph>
        </li>
        <li>
          <Paragraph>By phone number: 072-392-2392</Paragraph>
        </li>
        <li>
          <Paragraph>
            By mail: 6-8 Cobbleset Lane, New Germany, Pinetown, Durban, 3610
          </Paragraph>
        </li>
      </UnorderedList>
      <Heading2>Contact Information Regulatory</Heading2>
      <Paragraph>
        You may lodge a complaint to the Information Regulatory if you are
        unhappy with how we have used your information.
      </Paragraph>
      <UnorderedList>
        <li>
          <Paragraph>
            The Information Regulator (South Africa)
            <br />
            JD House
            <br /> 27 Stiemens Street
            <br /> Braamfontein
            <br /> Johannesburg
            <br /> 2001
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Their contact details are as follows: <br />
            <Anchor
              href="mailto:POPIAComplaints.IR@justice.gov.za"
              rel="external nofollow noopener"
              target="_blank"
            >
              Complaints email for POPIA: POPIAComplaints.IR@justice.gov.za
            </Anchor>
            <br />
            <br />
            <Anchor
              href="mailto:PAIAComplaints.IR@justice.gov.za"
              rel="external nofollow noopener"
              target="_blank"
            >
              Complaints email for PAIA: PAIAComplaints.IR@justice.gov.za
            </Anchor>
          </Paragraph>
        </li>
      </UnorderedList>
    </Container>
  );
};

export default PolicyPrivate;
