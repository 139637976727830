import { configureStore } from "@reduxjs/toolkit";
import postReducer from "../features/blogSlice/postSlice";
import portfolioReducer from "../features/portfolioSlice/portfolioSlice";

//Store
export const store = configureStore({
  reducer: {
    post: postReducer,
    portfolio: portfolioReducer,
  },
});
