import React from "react";
import { Link } from "react-router-dom";

const BlogLatestPost = ({ recentPosts }) => {
  let publicUrl = process.env.PUBLIC_URL;

  return (
    <>
      <div className="sidebar__single sidebar__post">
        <h3 className="sidebar__title">Recent Post</h3>
        <div className="sidebar__post-box">
          {recentPosts.map((post) => {
            return (
              <div className="sidebar__post-single" key={post._id}>
                <div className="sidebar-post__img">
                  {post?.image ? (
                    <img src={post.image.filePath} alt={post.image.fileName} />
                  ) : (
                    <p>No image set for this post</p>
                  )}
                </div>
                <div className="sidebar__post-content-box">
                  <h3>
                    <Link to={publicUrl +
                              `/blog-details/${post._id}`}>
                      {`${post.title + "..."}`}{" "}
                    </Link>
                  </h3>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BlogLatestPost;
