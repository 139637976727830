import React from 'react';
import HeaderThree from "../../common/header/HeaderThree";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import BrandOne from '../../components/brand/BrandOne';
import FooterOne from '../../common/footer/FooterOne';
import SingleGeneralBuildingConstruction from '../../components/single-service/SingleGeneralBuildingConstruction';


const GeneralBuildingConstruction = () => {
  return (
    <>
      <HeaderThree />
      <Breadcrumb
        heading="General Building Construction"
        currentPage="General Building Construction"
        Img="general_construction-bg-img.jpg"
      />
      <SingleGeneralBuildingConstruction /> 
      <BrandOne />
      <FooterOne />
    </>
  );
};

export default GeneralBuildingConstruction;