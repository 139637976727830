import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const API_URL = `${BACKEND_URL}/api/posts/`;

const createPost = async (FormData) => {
  const response = await axios.post(API_URL, FormData);
  return response.data;
};

//get all posts
const getPosts = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

//get a single posts
const getSinglePost = async (id) => {
  const response = await axios.get(API_URL + id);
  return response.data;
};

const postService = {
  createPost,
  getPosts,
  getSinglePost,
};

export default postService;
