import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import portfolioService from "./portfolioService";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const initialState = {
  portfolio: {},
  portfolios: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  error: undefined,
};

// actions
export const createPortfolio = createAsyncThunk(
  "portfolios/create",
  async (formData, thunkAPI) => {
    try {
      return await portfolioService.createPortfolio(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get all posts
export const getPortfolio = createAsyncThunk(
  "portfolios/getAll",
  async (_, thunkAPI) => {
    try {
      return await portfolioService.getPortfolio();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// //Get a single post
// export const getSinglePost = createAsyncThunk(
//   "posts/getSinglePost",
//   async (id, thunkAPI) => {
//     try {
//       return await postService.getSinglePost(id);
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  extraReducers: (builder) => {
    //handle actions
    //pending -> fulfilled -> rejected
    builder
      .addCase(createPortfolio.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPortfolio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.portfolios.push(action.payload);
        toast.success("Post added successfully");
      })
      .addCase(createPortfolio.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.success("Post added successfully");
      })
      .addCase(getPortfolio.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPortfolio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.portfolios = action.payload.data.portfolio;
        // console.log(state.posts)
      })
      .addCase(getPortfolio.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
    //   .addCase(getSinglePost.pending, (state) => {
    //     state.isLoading = true;
    //   })
    //   .addCase(getSinglePost.fulfilled, (state, action) => {
    //     state.isLoading = false;
    //     state.isSuccess = true;
    //     state.isError = false;
    //     //payload is dat coming from db
    //     state.post = action.payload.data.post;
    //   })
    //   .addCase(getSinglePost.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.isError = true;
    //     state.message = action.payload;
    //     toast.error(action.payload);
    //   });
  },
});

export const selectIsLoading = (state) => state.post.isLoading;
export const selectPost = (state) => state.portfolio.portfolio;

//Generate reducer
export default portfolioSlice.reducer;
