import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


const ContactForm = () => {
  
  // State variables for input fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const data = {
    subject,
    message,
    name,
    phone,
    email,
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    console.log("Form data:", {
      name,
      email,
      phone,
      subject,
      message,
    });
    try {
      const response = await axios.post(`${BACKEND_URL}/api/contactus`, data);
      // Reset form fields (optional)
      setName("");
      setEmail("");
      setPhone("");
      setSubject("");
      setMessage("");

      toast.success(response.data.message);
    } catch (error) {
      toast.error(`${error.message},  Please Try again`);
    }
  };

  // Event handlers to update state variables
  const handleFullNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  // Form submission handler


  return (
    <>
      <div className="contact-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-5">
              <div className="contact-page__content">
                <div className="title">
                  <h2>Get In Touch</h2>
                </div>

                <div className="contact-page__content-single">
                  <div className="contact-page__content-single-inner">
                    <div className="icon-box">
                      <span className="icon-pin"></span>
                    </div>

                    <div className="content-box">
                      <h2>Address</h2>
                      <p>
                        6-8 Cobbleset Lane, New Germany <br /> Pinetown Durban 3610.
                      </p>
                      <br/>
                      <p>
                        688 Gallagher Avenue, Halfway House <br /> Midrand, Johannesburg 1686.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="contact-page__content-single">
                  <div className="contact-page__content-single-inner">
                    <div className="icon-box">
                      <span className="icon-phone-call-1"></span>
                    </div>

                    <div className="content-box">
                      <h2>Phone</h2>
                      <p className="number1">
                        <a href="tel:0723922392">072-392-2392</a>
                      </p>
                      <p className="number2">
                        <a href="tel:061 917 0163">061 917 0163</a>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="contact-page__content-single">
                  <div className="contact-page__content-single-inner">
                    <div className="icon-box">
                      <span className="icon-email"></span>
                    </div>

                    <div className="content-box">
                      <h2>Email </h2>
                      <p className="email1">
                        <a href="mailto:tenders@simandlovu.co.za">
                          tenders@simandlovu.co.za
                        </a>
                      </p>
                      <p className="email2">
                        <a href="mailto:simandlovutrading@gmail.com">
                          simandlovutrading@gmail.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-8 col-lg-8 col-md-7">
              <div className="contact-page__form">
                <form
                  action="#"
                  className="comment-one__form contact-form-validated"
                  onSubmit={sendEmail} //handle form submission
                  noValidate
                >
                  <div className="row">
                    <div className="col-xl-6 col-lg-6">
                      <div className="comment-form__input-box">
                        <input
                          type="text"
                          placeholder="Full name"
                          name="name"
                          value={name} // Set input value
                          onChange={handleFullNameChange} // Handle input change
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <div className="comment-form__input-box">
                        <input
                          type="email"
                          placeholder="Email address"
                          name="email"
                          value={email} // Set input value
                          onChange={handleEmailChange} // Handle input change
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6">
                      <div className="comment-form__input-box">
                        <input
                          type="text"
                          placeholder="Phone"
                          name="phone"
                          value={phone} // Set input value
                          onChange={handlePhoneChange} // Handle input change
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <div className="comment-form__input-box">
                        <input
                          type="text"
                          placeholder="Subject"
                          name="subject"
                          value={subject} // Set input value
                          onChange={handleSubjectChange} // Handle input change
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12 col-lg-12">
                      <div className="comment-form__input-box text-message-box">
                        <textarea
                          name="message"
                          placeholder="Your Message"
                          value={message} // Set textarea value
                          onChange={handleMessageChange} // Handle textarea change
                        ></textarea>
                      </div>

                      <button
                        className="thm-btn comment-form__btn"
                        data-text="Send Message +"
                        type="submit"
                        data-loading-text="Please wait..."
                      >
                        Send Message +
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
