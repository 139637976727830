import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlogCategory from "./BlogCategory";
import BlogLatestPost from "./BlogLatestPost";
import BlogQuery from "./BlogQuery";
import BlogSearch from "./BlogSearch";
import BlogTags from "./BlogTags";
import { useDispatch, useSelector } from "react-redux";
import { getPosts } from "../../redux/features/blogSlice/postSlice";
import formatDate from "../../services/formDate";
import DOMPurify from "dompurify";
import { Pagination } from "./pagination/Pagination";
import { SpinnerImg } from "../../components/loader/Loader";

const BlogSeven = () => {
  const dispatch = useDispatch();
  // User is currntly on this page
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(4);

  const { posts, isLoading, isError, message } = useSelector(
    (state) => state.post
  );
  const postsFromRedux = posts;

  useEffect(() => {
    const $ = window.$;

    if ($(".video-popup").length) {
      $(".video-popup").magnificPopup({
        type: "iframe",
        mainClass: "mfp-fade",
        removalDelay: 160,
        preloader: true,

        fixedContentPos: false,
      });
    }
    dispatch(getPosts());
  }, [dispatch, isError, message]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const currentRecords = postsFromRedux.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const recentPosts = postsFromRedux.slice(0, 3);

  const nPages = Math.ceil(postsFromRedux.length / recordsPerPage);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <>
      {isLoading && <SpinnerImg />}
      <section className="blog-standard">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-standard__left">
                {currentRecords.map((post) => {
                  //Map the posts to JSX
                  return (
                    <div className="blog-standard__single" key={post._id}>
                      <div className="blog-standard__single-img">
                        {post?.image ? (
                          <img
                            src={post.image.filePath}
                            alt={post.image.fileName}
                          />
                        ) : (
                          <p>No image set for this post</p>
                        )}
                        <div className="blog-standard__date">
                          {formatDate(post.createdAt)}
                        </div>
                      </div>
                      <div className="blog-standard__single-content">
                        <ul className="meta-info">
                          <li>
                            <span className="icon-account"></span>
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                `/blog-details/${post._id}`
                              }
                            >
                              {post.author}
                            </Link>
                          </li>
                          <li>
                            <span className="icon-account"></span>
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                `/blog-details/${post._id}`
                              }
                            >
                              {post.services[0]}
                            </Link>
                          </li>
                        </ul>
                        <h2>
                          <Link
                            to={
                              process.env.PUBLIC_URL +
                              `/blog-details/${post._id}`
                            }
                          >
                            {post.title}
                          </Link>
                        </h2>

                        <div className="btn-box">
                          <Link
                            to={
                              process.env.PUBLIC_URL +
                              `/blog-details/${post._id}`
                            }
                          >
                            Read More <span className="icon-plus-sign"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* {Start Pagination}   */}
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>

            {/* Start Sidebar */}
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar sidebar__blog-standard">
                <BlogSearch />
                {/* <BlogCategory /> */}
                <BlogLatestPost recentPosts={recentPosts} />
                <BlogQuery />
                {/* <BlogTags /> */}
              </div>
            </div>
            {/* End Sidebar */}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogSeven;
